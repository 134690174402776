import React, { Component } from 'react'
import './Footer.css'

export default class Footer extends Component {
  render () {
    var currentTime = new Date()
    var year = currentTime.getFullYear()
    return (
      <div className='Footer'>
        <div className='footerLogo'>
          <img className='footerImg' src={require('./logoUniMelb.svg')} alt='The University of Melbourne'/>
        </div>
        <div className='footerText'>
          <div>
            <p>© The University of Melbourne { year }</p>
            <p><a className="footerLink" href="/about">Privacy Statement</a></p>
          </div>
        </div>
        <div className='footerEnd'>
        </div>
      </div>
    )
  }
}
