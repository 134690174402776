import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer'
import flagActivePng from './flag_active.png'
import flagInactivePng from './flag_inactive.png'
import './Plan.css'

const dateString = (d) => {
  return d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2)
}
const minPage = 1
const maxPage = 12
const helperPage = 1
const qstartPage = 2
const qendPage = 5
const schedulePage = 6
const activityPage = 7
const sleepPage = 8
const moodPage = 9
const feelPage = 10
const welldonePage = 11

function dateStringFormat(datestring) {
  // yyyy-mm-dd string change to dd/mm/yyyy string
  const [year, month, day] = datestring.split('-');
  return [day, month, year].join('/');
}

function customDatesBuild(prescriptions) {
  const customDates = JSON.parse(JSON.stringify(prescriptions));
  for (var i = 0; i < customDates.length; i++) {
    customDates[i].date = dateStringFormat(customDates[i].date)
  }
  return customDates;
}

export default class Plan extends Component {
  constructor (props) {
    super(props)
    if (props.schedule != null) {
      var s = props.schedules.find((s) => s.id === props.schedule)
      var dfind = props.medication.find((m) => m.id === s.medication)
    }
    this.state = {
      activityText: '',
      sleepText: '',
      moodText: '',
      activeText: '',
      prescriptionText: '',
      dose: '',
      prescriptionDate: dateString(new Date()),
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      email: props.user.email,
      phone: props.user.phone || '',
      password: '',
      isUserSaved: false,
      schedule: props.schedule,
      timesperday: props.timesperday,
      startdose: props.startdose,
      isEditingUser: false,
      isEditingMeds: false,
      isEditingDose: false,
      isEditingMedCustom: false,
      customDates: customDatesBuild(this.props.prescriptions),
      isEditingActivities: false,
      isEditingSleep: false,
      isEditingFeel: false,

      page: props.schedule === null ? minPage : maxPage,
      answers: props.setupAnswers,
      doses: dfind != null ? dfind.doses : null,

      isEditingDoctor: props.schedule === null ? true : false,
      isDoctorSaved: true,
      doctorName: props.doctor.name,
      doctorPhone: props.doctor.phone,
      doctorEmail: props.doctor.email,
      isEditingSupport: props.schedule === null ? true : false,
      isSupportSaved: true,
      supportName: props.support.name,
      supportPhone: props.support.phone,
      adminActing: props.adminActing,
    }
    window.scrollTo(0, 0)
  }
  handleActivityTextChange = (e) => { this.setState({activityText: e.target.value}) }
  handleSleepTextChange = (e) => { this.setState({sleepText: e.target.value}) }
  handleMoodTextChange = (e) => { this.setState({moodText: e.target.value}) }
  handleActiveTextChange = (e) => { this.setState({activeText: e.target.value}) }
  handleAddActivityClick = () => {
    const str = this.state.activityText.trim()
    if (str.length) {
      this.props.onAddActivity(str)
    }
    this.setState({activityText: ''})
  }
  handleNextActivityClick = () => {
    const str = this.state.activityText.trim()
    if (str.length) {
      this.props.onAddActivity(str)
    }
    this.setState({activityText: '', page: this.state.page+1})
    window.scrollTo(0, 0)
  }

  handleAddSleepClick = () => {
    const str = this.state.sleepText.trim()
    if (str.length) {
      this.props.onAddSymptom({text: str, category: 'sleep'})
    }
    this.setState({sleepText: ''})
  }
  handleNextSleepClick = () => {
    const str = this.state.sleepText.trim()
    if (str.length) {
      this.props.onAddSymptom({text: str, category: 'sleep'})
    }
    this.setState({sleepText: '', page: this.state.page+1})
    window.scrollTo(0, 0)
  }

  handleAddMoodClick = () => {
    const str = this.state.moodText.trim()
    if (str.length) {
      this.props.onAddSymptom({text: str, category: 'mood'})
    }
    this.setState({moodText: ''})
  }
  handleNextMoodClick = () => {
    const str = this.state.moodText.trim()
    if (str.length) {
      this.props.onAddSymptom({text: str, category: 'mood'})
    }
    this.setState({moodText: '', page: this.state.page+1})
    window.scrollTo(0, 0)
  }

  handleAddFeelClick = () => {
    const str = this.state.activeText.trim()
    if (str.length) {
      this.props.onAddSymptom({text: str, category: 'active'})
    }
    this.setState({activeText: ''})
  }
  handleNextFeelClick = () => {
    const str = this.state.activeText.trim()
    if (str.length) {
      this.props.onAddSymptom({text: str, category: 'active'})
    }
    this.setState({activeText: '', page: this.state.page+1})
    window.scrollTo(0, 0)
  }

  handleRemoveActivityClick = (id) => {
    this.props.onRemoveActivity(id)
  }
  handleRemoveSymptomClick = (id) => {
    this.props.onRemoveSymptom(id)
  }
  handleFlagClick = (id) => {
    this.props.onFlagToggle(id)
  }

  handleFirstNameChange = (e) => this.setState({
    firstName: e.target.value,
    isUserSaved: false,
  })
  handleLastNameChange = (e) => this.setState({
    lastName: e.target.value,
    isUserSaved: false,
  })
  handlePhoneChange = (e) => this.setState({
    phone: e.target.value,
    isUserSaved: false,
  })
  handleEmailChange = (e) => this.setState({
    email: e.target.value,
    isUserSaved: false,
  })
  handlePasswordChange = (e) => this.setState({
    password: e.target.value,
    isUserSaved: false,
  })

  handleSaveUserClick = () => {
    this.setState({
      isSavingUser: true,
    })
    this.props.onSaveUser({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
    })
    .then(() => {
      this.setState({isUserSaved: true, isSavingUser: false, isEditingUser: false})
    })
    .catch((e) => {
      console.log(e)
    }) 
  }

  handleScheduleOptionClick = (index) => {
    this.props.onSaveSchedule(index)
    .then(() => {
      this.setState({
        schedule: index,
        startdose: this.props.startdose,
        isEditingMeds: false,
        customDates: customDatesBuild(this.props.prescriptions), 
      })
    })
    .catch((e) => {
      console.log(e)
    })
  }
  
  handleDoseOptionClick = (index, dose) => {
    this.setState({
      startdose: dose,
      isEditingDose: false
    })
    this.props.onSaveDose(index)
    .then(() => {
      this.setState({
        prescriptions: this.props.prescriptions,
        customDates: customDatesBuild(this.props.prescriptions),
      })
    })
  }
  
  handleCustomDateChange = (index, value) => {
    const newDates = [...this.state.customDates]
    newDates[index].date = value;
    this.setState({
      customDates: newDates,
    })
  }
  handleCustomDoseChange = (index, value) => {
    const newDates = [...this.state.customDates]
    newDates[index].dose = value;
    this.setState({
      customDates: newDates,
    })
  }
  handleCustomNoteChange = (index, value) => {
    const newDates = [...this.state.customDates]
    newDates[index].note = value;
    this.setState({
      customDates: newDates,
    })
  }
  
  handleMedCustomButtonClick = () => {
    this.setState({
      isEditingMedCustom: false
    })
    this.props.onSaveMedCustom(this.state.customDates)
    .then(() => {
      this.setState({
        customDates: customDatesBuild(this.props.prescriptions),
      })
    })
  }
  
  handleEditUserClick = () => this.setState({isEditingUser: !this.state.isEditingUser})
  handleEditMedsClick = () => this.setState({isEditingMeds: !this.state.isEditingMeds})
  handleEditDoseClick = () => this.setState({isEditingDose: !this.state.isEditingDose})
  handleEditMedCustomClick = () => this.setState({isEditingMedCustom: !this.state.isEditingMedCustom})
  handleEditActivitiesClick = () => this.setState({isEditingActivities: !this.state.isEditingActivities})

  handleEditSleepClick = () => this.setState({isEditingSleep: !this.state.isEditingSleep})
  handleEditMoodClick = () => this.setState({isEditingMood: !this.state.isEditingMood})
  handleEditFeelClick = () => this.setState({isEditingFeel: !this.state.isEditingFeel})

  handleBackClick = () => (this.state.page > minPage ? this.setState({page: this.state.page-1, isEditingActivities: true, isEditingSleep: true, isEditingMood: true, isEditingFeel: true, isEditingDoctor: true, isEditingSupport: true}, () => {window.scrollTo(0, 0)}) : this.setState({page: minPage}))

  handleNextSetupClick = () => {
    if (this.state.page === qendPage) {
      this.props.getSetupScore()
      .then(res => {
        if (!res.ok) {
          throw new Error()
        }
        else {
          return res
        }
      })
      .then(res => res.json())
      .then(({schedule, timesperday, startdose}) => {
        this.setState({ 
          schedule: schedule,
          timesperday: timesperday,
          startdose: startdose,
        })
        this.props.onSaveSchedule(schedule)
        .then(() => {
          this.setState({
            customDates: customDatesBuild(this.props.prescriptions), 
          })
        })
      })
    }
    this.setState({page: this.state.page+1})
    window.scrollTo(0, 0)
  }

  handleNextScheduleClick = () => {
    this.setState({
      page: this.state.page+1,
      isEditingActivities: true,
      isEditingSleep: true,
      isEditingMood: true,
      isEditingFeel: true,
    })
    window.scrollTo(0, 0)
  }

  handleOptionClick = (q, index, oid) => {
    this.props.submitSetupOption(q, oid)
    this.setState({answers: this.state.answers.map((a) => a.qid === q ? {'qid': q, 'oid': oid} : a)})
    if (q === 1) {
      var d = this.props.medication.find((m) => m.setupoption === oid)
      this.setState({doses: d != null ? d.doses : null})
    }
  }

  handleEditDoctorClick = () => this.setState({isEditingDoctor: !this.state.isEditingDoctor})
  handleDoctorNameChange = (e) => this.setState({doctorName: e.target.value})
  handleDoctorPhoneChange = (e) => this.setState({doctorPhone: e.target.value})
  handleDoctorEmailChange = (e) => this.setState({doctorEmail: e.target.value})
  handleSaveDoctorClick = () => {
    this.setState({isSavingDoctor: true})
    this.props.onSaveDoctor({
      name: this.state.doctorName,
      phone: this.state.doctorPhone,
      email: this.state.doctorEmail,
    })
    .then(() => {
      this.setState({isDoctorSaved: true, isSavingDoctor: false, isEditingDoctor: false})
    })
    .catch((e) => {
      console.log(e)
    })
  }

  handleEditSupportClick = () => this.setState({isEditingSupport: !this.state.isEditingSupport})
  handleSupportNameChange = (e) => this.setState({supportName: e.target.value})
  handleSupportPhoneChange = (e) => this.setState({supportPhone: e.target.value})
  handleSaveSupportClick = () => {
    this.setState({isSavingSupport: true})
    this.props.onSaveSupport({
      name: this.state.supportName,
      phone: this.state.supportPhone,
    })
    .then(() => {
      this.setState({isSupportSaved: true, isSavingSupport: false, isEditingSupport: false})
    })
    .catch((e) => {
      console.log(e)
    })
  }
  
  handleNextHelperClick = () => {
    this.props.onSaveDoctor({
      name: this.state.doctorName,
      phone: this.state.doctorPhone,
      email: this.state.doctorEmail,
    })
    .then(() => {
      this.props.onSaveSupport({
        name: this.state.supportName,
        phone: this.state.supportPhone,
      })
    })
    .then(() => {
      this.setState({page: this.state.page+1})
      window.scrollTo(0, 0)
    })
    .catch((e) => {
      console.log(e)
    })
  }
  
  handlePrintClick = () => window.print();

  render () {
    return (
      <div>
        <div className='Plan'>
          <div className='topGap'></div>
          {
            this.state.page < maxPage ? (
              <div className='firstTime'>
                <div className='topQuote'>
                  <div className='portraitCont'>
                    <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                    <div className='caption'>Professor Jane Gunn</div>
                  </div>
                  <div className='quote'>
                    { 
                      this.state.page === 1 ? (
                        'Let’s check your details are correct and up to date'
                      ) : this.state.page === 2 ? (
                        'Tell us more about your current depression medication'
                      ) : this.state.page === 3 ? (
                        'Tell us about your experiences of taking your antidepressant medication'
                      ) : this.state.page === 4 ? (
                        'Tell us more about your daily activity levels '
                      ) : this.state.page === schedulePage ? (
                        'Thank you for completing these questions. Below we have calculated your recommended deprescribing schedule that are in line with national clinical guidelines. Please make an appointment to discuss this schedule with your GP and ask them for a new prescription if they agree with the recommendation.'
                      ) : this.state.page === activityPage ? (
                        'The next few pages are to enable you to set up your wellbeing checker ready for when you start to reduce your medication. We will ask you to tell us about activities you enjoy as well as your sleep, mood and current activity levels and how these might be affected by depression.'
                      ) : this.state.page === sleepPage ? (
                        'Daily well-being tracker set up: Sleep.'
                      ) : this.state.page === moodPage ? (
                        'Daily well-being tracker set up: Mood.'
                      ) : this.state.page === feelPage ? (
                        'Daily well-being tracker set up: Activities.'
                      ) : this.state.page === welldonePage ? (
                        'Congratulations! You have set up your well-being plan.'
                      ) : null
                    }
                  </div>
                </div>
                {
                  this.state.page >= qstartPage && this.state.page <= qendPage ? (
                    <div className='wrapper'>
                      <Question questions={this.props.setupQuestions[this.state.page-2]} doses={this.state.doses} index={this.state.page} onBackClick={this.handleBackClick} onNextSetupClick={this.handleNextSetupClick} answers={this.state.answers} medication={this.state.medication} onOptionClick={this.handleOptionClick}/>
                    </div>
                  ) : null
                }
                {
                  this.state.page === welldonePage ? (
                    <div className='wrapper'>
                      <div className='group'>
                        <label className='title'>
                          Next steps
                        </label>
                        <div className="instruction">
                          <p>You’re all done for now!</p>
                          <p>Please arrange to speak with your doctor about your new medication prescription.</p>
                          <p>When you are ready to start reducing your medication we will ask you to log into to your wellbeing tracker and record how you are feeling every day. It will only take 2 minutes.</p>
                          <p>Ask the research team how to bookmark your log in page to make it easier for you.</p>
                        </div>
                      </div>
                      <div className='group'>
                        <div onClick={this.handleBackClick} className='back'>
                          Back
                        </div>
                        <Link to='/' className='next'>
                            Next
                        </Link>
                      </div>
                    </div>
                  ) : null
                }
              </div>
            ) : null
          }

          {
            this.state.page === helperPage || (this.state.page >= schedulePage && this.state.page !== welldonePage) ? (
              <div className='wrapper'>
                {
                  this.state.page === maxPage ? (
                    <div className='group firstGroup'>
                      <label className='title'>
                        {this.props.user.firstName}
                        <div className='editIcon' onClick={this.handleEditUserClick}/>
                      </label>
                      {
                        this.state.isEditingUser ? (
                          <div>
                            <div className='spaced'>
                              <label htmlFor='firstname'>Name</label>
                              <input id='firstname' name='firstname' type='text' value={this.state.firstName} onChange={this.handleFirstNameChange}/>
                            </div>

                            <div className='spaced'>
                              <label htmlFor='phone'>Phone</label>
                              <input id='phone' name='phone' type='tel' pattern='[0-9]{4}-[0-9]{3}-[0-9]{3}' value={this.state.phone} onChange={this.handlePhoneChange}/>
                            </div>

                            <div className='spaced'>
                              <label htmlFor='email'>Email</label>
                              <input id='email' name='email' type='email' value={this.state.email} onChange={this.handleEmailChange}/>
                            </div>

                            <div className='spaced'>
                              <label htmlFor='email'>Password (leave empty to keep your current password)</label>
                              <input id='pwd' name='pwd' type='password' value={this.state.password} onChange={this.handlePasswordChange}/>
                            </div>

                            <div className='saveBox'>
                              <div onClick={this.handleEditUserClick} className='back'>Cancel</div>
                              <SaveButton onClick={this.handleSaveUserClick} isSaved={this.state.isUserSaved} isSaving={this.state.isSavingUser}/>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className='persondetail'>
                              {this.props.user.phone}
                            </div>
                            <div className='persondetail'>
                              {this.props.user.email}
                            </div>
                          </div>
                        )
                      }
                    </div>
                  ) : null
                }

                {
                  this.state.page === maxPage || this.state.page === schedulePage ? (
                    <div className='group'>
                      <label className='title'>
                        Medication
                        { 
                          this.state.page !== maxPage || this.state.adminActing ? (
                            <div className='editIcon' onClick={this.handleEditMedsClick}/>
                          ) : null
                        }
                      </label>
                      <div className='instruction'>
                        Please contact your supervising nurse or doctor if you wish to change your medication.
                      </div>
                      {
                        this.state.isEditingMeds ? (
                          <div>
                            <div className='instruction'>
                              Select a medication schedule in consultation with your Doctor or Nurse.
                            </div>
                            <div className='scheduleOptions'>
                              {
                                this.props.schedules.map((s) => (
                                  <ScheduleOption key={s.id} index={s.id} onClick={this.handleScheduleOptionClick} active={this.state.schedule === s.id}>
                                    {s.text}
                                  </ScheduleOption>
                                ))
                              }
                            </div>
                          </div>
                        ) : (
                          <div className="scheduleDesc">
                            {
                              this.state.page === schedulePage ? (
                                <div className='recommendTop'>
                                  Ask your Doctor for a prescription if they agree with the recommendation below:
                                </div>
                              ) : null
                            }
                            <div className='recommendSchedule'>
                              {
                                this.props.schedules.map((s) => ( 
                                  s.id === this.state.schedule ? (
                                    <div key={s.id}>
                                      <h3>{ s.text }</h3>
                                      <div onClick={this.handlePrintClick} className='print'>Print</div>
                                      <ul>{ this.props.prescriptions.map((p, i, {length}) => (<li key={p.id}>{ dateStringFormat(p.date) } - {i === 0 ? 'Current dose ' : i !== length-1 ? 'Reduce to ' : ''}{p.note} {i !== length-1 ? 'for '+p.days+' days' : '' }{ this.state.timesperday === 2 && (this.state.schedule === 17 || this.state.schedule === 18) ? ( p.dose === 300 ? ' (150mg twice a day)' : p.dose === 225 ? ' (150mg once a day and 75mg once a day)' : p.dose === 150 ? ' (75mg twice a day)' : p.dose === 75 ? ' (75mg once a day)' : p.dose === 37 ? ' (37.5mg once a day)' : '') : '' } {i === 0 ? <br /> : null}{i === 0 ? 'This gives you time to consult with your GP to confirm the prescription. Please let the research team know when you are ready to start.' : null}</li>))}</ul>
                                    </div>
                                  ) : null
                                ))
                              }
                            </div>
                          </div>
                        )
                      }
                      { 
                        this.state.adminActing || this.state.page !== maxPage ? (
                          <label className='dosetitle'>
                            Change Schedule
                            <div className='editIcon' onClick={this.handleEditDoseClick}/>
                          </label>
                        ) : null
                      }
                      {
                        this.state.isEditingDose ? (
                          <div>
                            <div className='scheduleOptions'>
                              {
                                this.props.prescriptionsDefault.map((pd) => (
                                    pd.scheduleid === this.state.schedule ? (
                                      <DoseOption key={pd.id} index={pd.id} dose={pd.dose} onClick={this.handleDoseOptionClick} active={pd.dose === this.props.startdose ? true : false}>
                                        {pd.note}
                                      </DoseOption>
                                    ) : null
                                ))
                              }
                            </div>
                          </div>
                        ) : null
                      }
                      
                      { 
                        this.state.adminActing || this.state.page !== maxPage ? (
                          <label className='dosetitle'>
                            Edit Schedule Details
                            <div className='editIcon' onClick={this.handleEditMedCustomClick}/>
                          </label>
                        ) : null
                      }
                      {
                        this.state.isEditingMedCustom ? (
                          <div>
                            <div className='scheduleMedCustom'>
                              {
                                this.props.schedules.map((s) => ( 
                                  s.id === this.state.schedule ? (
                                    <div key={s.id}>
                                      { this.state.customDates.map((p, i) => (
                                          <div key={p.id} className='scheduleMedCustomRow'>
                                            <DateInput key={'date'+p.id} index={i} date={p.date} onChange={this.handleCustomDateChange} />
                                            <DoseInput key={'dose'+p.id} index={i} dose={p.dose} onChange={this.handleCustomDoseChange} />
                                            <NoteInput key={'note'+p.id} index={i} note={p.note} onChange={this.handleCustomNoteChange} />
                                          </div>
                                        ))
                                      }
                                      <div onClick={this.handleMedCustomButtonClick} className='saveMed'>
                                        Save
                                      </div>
                                    </div>
                                  ) : null
                                ))
                              }
                            </div>
                          </div>
                        ) : null
                      }
                      { 
                        this.state.page === schedulePage ? (
                          <div className="buttons">
                            <div onClick={this.handleBackClick} className='back'>
                              Back
                            </div>
                            <div onClick={this.handleNextScheduleClick} className='next'>
                              Next
                            </div>
                          </div>
                        ) : null
                      }
                    </div>
                  ) : null
                }

                {
                  this.state.page === maxPage || this.state.page === helperPage ? (
                    <div>
                      {
                        this.state.page === helperPage ? (
                          <div className='longText questionTopText'>Page 1 of 5 - Do these pages with your Doctor or Nurse</div>
                        ) : null
                      }
                      <div className='group groupDoc'>
                        <div className='title titleSpace'>
                          Doctor or Nurse
                          { 
                            this.state.page !== helperPage ? (
                              <div className='editIcon' onClick={this.handleEditDoctorClick}/>
                            ) : null
                          }
                        </div>
                        {
                          !this.state.isEditingDoctor ? (
                            <div>
                              <div className='persondetail'>{this.props.doctor.name}</div>
                              <div className='persondetail'>{this.props.doctor.phone}</div>
                              <div className='persondetail'>{this.props.doctor.email}</div>
                            </div>
                          ) : (
                            <div>
                              <div className='instructionHelper'>
                                The Doctor or Nurse is there to help if you have concerns or questions. They will be sent updates on your progress.
                              </div>
                              <div className='spaced'>
                                <label htmlFor='doctorName'>Name</label>
                                <input name='doctorName' className='textInput' type='text' onChange={this.handleDoctorNameChange} value={this.state.doctorName}/>
                              </div>
                              <div className='spaced'>
                                <label htmlFor='doctorPhone'>Phone</label>
                                <input name='doctorPhone' className='textInput' type='tel' onChange={this.handleDoctorPhoneChange} value={this.state.doctorPhone}/>
                              </div>
                              <div className='spaced'>
                                <label htmlFor='doctorEmail'>Email</label>
                                <input name='doctorEmail' className='textInput' type='email' onChange={this.handleDoctorEmailChange} value={this.state.doctorEmail}/>
                              </div>
                              { 
                                this.state.page !== helperPage ? (
                                  <div className='spaced saveBox'>
                                    <div onClick={this.handleEditDoctorClick} className='back'>Cancel</div>
                                    <SaveButton isSaving={this.state.isSavingDoctor} isSaved={this.state.isDoctorSaved} onClick={this.handleSaveDoctorClick}/>
                                  </div>
                                ) : null
                              }
                            </div>
                          )
                        }
                      </div>
                      <div className='group groupSupport'>
                        <div className='title titleSpace'>
                          Support person
                          { 
                            this.state.page !== helperPage ? (
                              <div className='editIcon' onClick={this.handleEditSupportClick}/>
                            ) : null
                          }
                        </div>
                        {
                          !this.state.isEditingSupport ? (
                            <div>
                              <div className='persondetail'>{this.props.support.name}</div>
                              <div className='persondetail'>{this.props.support.phone}</div>
                              <div className='persondetail'>{this.props.support.email}</div>
                            </div>
                          ) : (
                            <div>
                              <div className='instructionHelper'>
                                You can also add a support person if you wish. For example a friend or family member. They will not be sent information, but their phone number will be stored so you can access their number directly from WiserAD.
                              </div>
                              <div className='spaced'>
                                <label htmlFor='supportName'>Name</label>
                                <input name='supportName' className='textInput' type='text' onChange={this.handleSupportNameChange} value={this.state.supportName}/>
                              </div>
                              <div className='spaced'>
                                <label htmlFor='supportPhone'>Phone</label>
                                <input name='supportPhone' className='textInput' type='tel' onChange={this.handleSupportPhoneChange} value={this.state.supportPhone}/>
                              </div>
                              { 
                                this.state.page !== helperPage ? (
                                  <div className='spaced saveBox'>
                                    <div onClick={this.handleEditSupportClick} className='back'>Cancel</div>
                                    <SaveButton isSaving={this.state.isSavingSupport} isSaved={this.state.isSupportSaved} onClick={this.handleSaveSupportClick}/>
                                  </div>
                                ) : null
                              }
                            </div>
                          )
                        }
                      </div>
                    </div>                 
                  ) : null
                }
                {
                  this.state.page === maxPage || this.state.page === activityPage ? (
                    <div className='group groupActivities'>
                      <label className='title'>
                        Activities
                        { this.state.page === maxPage ?
                          <div className='editIcon' onClick={this.handleEditActivitiesClick}/>
                          : null
                        }
                      </label>
                      {
                        this.state.isEditingActivities ? (
                          <div className='instruction'>
                            What activities do you take part in to help with your wellbeing? Feel free to add in others that aren’t listed here in the box at the bottom of the list and then press ADD. Use the MINUS button to remove any that aren’t relevant to you.
                          </div>
                        ) : null
                      }
                      <div>
                        {
                          this.props.activities.map(({id, text}) => (
                            <div className='activity' key={id}>
                              {text}
                              {
                                this.state.isEditingActivities ? (
                                  <RemoveIcon index={id} onClick={this.handleRemoveActivityClick}/>
                                ) : null
                              }
                            </div>
                          ))
                        }
                      </div>
                      {
                        this.state.isEditingActivities ? (
                          <div className='spaced'>
                            <input name='activityName' type='text' value={this.state.activityText} onChange={this.handleActivityTextChange} className='inlineInput'/>
                            <AddButton onClick={this.handleAddActivityClick}/>
                          </div>
                        ) : null
                      }
                    </div>
                  ) : null
                }

                {
                  this.state.page === maxPage || this.state.page === sleepPage || this.state.page === moodPage || this.state.page === feelPage ? (
                    <div className='group groupTrack'>
                      <label className='title'>
                      { 
                        this.state.page === sleepPage ? (
                          'Sleep symptoms'
                        ) : this.state.page === moodPage ? (
                          'Mood symptoms'
                        ) : this.state.page === feelPage ? (
                          'Symptoms that may affect activity'
                        ) : null
                      }
                      </label>
                      <div className='instruction'>
                        If any symptom is a particular warning sign that you are becoming depressed, mark it with a red flag.
                      </div>
                    </div>
                  ) : null
                }

                {
                  this.state.page === maxPage || this.state.page === sleepPage ? (
                    <div className='group groupTrack'>
                      <div className='titleTracker'>
                        When I am depressed my sleep can be affected by:
                        { this.state.page === maxPage ?
                          <div className='editIcon' onClick={this.handleEditSleepClick}/>
                          : null
                        }
                      </div>
                      <div className='spaced'>
                        {
                          this.props.symptoms.map(({text, category, id}, i) => (
                            category === 'sleep' ? (
                              <div className='symptom' key={id}>
                                <FlagIcon active={this.props.symptoms[i].redflag} id={id} onClick={this.handleFlagClick}/>
                                <div className='symptomText'>{text}</div>
                                {
                                  this.state.isEditingSleep ? (
                                    <RemoveIcon key={id} index={id} onClick={this.handleRemoveSymptomClick}/>
                                  ) : null
                                }
                              </div>
                            ) : null
                          ))
                        }
                      </div>
                      {
                        this.state.isEditingSleep ? (
                          <div className='spaced'>
                            <input name='symptomName' type='text' value={this.state.sleepText} onChange={this.handleSleepTextChange} className='inlineInput'/>
                            <AddButton onClick={this.handleAddSleepClick}/>
                          </div>
                        ) : null
                      }
                    </div>
                  ) : null
                }

                {
                  this.state.page === maxPage || this.state.page === moodPage ? (
                    <div className='group groupTrack'>
                      <div className='titleTracker'>
                        When I am becoming depressed I experience:
                        { this.state.page === maxPage ?
                          <div className='editIcon' onClick={this.handleEditMoodClick}/>
                          : null
                        }
                      </div>
                      <div className='spaced'>
                        {
                          this.props.symptoms.map(({text, category, id}, i) => (
                            category === 'mood' ? (
                              <div className='symptom' key={id}>
                                <FlagIcon active={this.props.symptoms[i].redflag} id={id} onClick={this.handleFlagClick}/>
                                {text}
                                {
                                  this.state.isEditingMood ? (
                                    <RemoveIcon key={id} index={id} onClick={this.handleRemoveSymptomClick}/>
                                  ) : null
                                }
                              </div>
                            ) : null
                          ))
                        }
                      </div>
                      {
                        this.state.isEditingMood ? (
                          <div className='spaced'>
                            <input name='symptomName' type='text' value={this.state.moodText} onChange={this.handleMoodTextChange} className='inlineInput'/>
                            <AddButton onClick={this.handleAddMoodClick}/>
                          </div>
                        ) : null
                      }
                    </div>
                  ) : null
                }

                {
                  this.state.page === maxPage || this.state.page === feelPage ? (
                    <div className='group groupTrack'>
                      <div className='titleTracker'>
                        Active?
                        { this.state.page === maxPage ?
                          <div className='editIcon' onClick={this.handleEditFeelClick}/>
                          : null
                        }
                      </div>
                      <div className='spaced'>
                        {
                          this.props.symptoms.map(({text, category, id}, i) => (
                            category === 'active' ? (
                              <div className='symptom' key={id}>
                                <FlagIcon active={this.props.symptoms[i].redflag} id={id} onClick={this.handleFlagClick}/>
                                {text}
                                {
                                  this.state.isEditingFeel ? (
                                    <RemoveIcon key={id} index={id} onClick={this.handleRemoveSymptomClick}/>
                                  ) : null
                                }
                              </div>
                            ) : null
                          ))
                        }
                      </div>
                      {
                        this.state.isEditingFeel ? (
                          <div className='spaced'>
                            <input name='symptomName' type='text' value={this.state.activeText} onChange={this.handleActiveTextChange} className='inlineInput'/>
                            <AddButton onClick={this.handleAddFeelClick}/>
                          </div>
                        ) : null
                      }
                    </div>
                  ) : null
                }
                
                { 
                  this.state.page >= activityPage || this.state.page === helperPage ? (
                    <div className="buttonsExtra">
                      { 
                        this.state.page !== welldonePage && this.state.page !== minPage ? (
                          <div onClick={this.handleBackClick} className='back'>
                            Back
                          </div>
                        ) : null
                      }
                      {
                        this.state.page === activityPage ? (
                          <div onClick={this.handleNextActivityClick} className='next'>
                            Next
                          </div>
                        ) : this.state.page === sleepPage ? (
                          <div onClick={this.handleNextSleepClick} className='next'>
                            Next
                          </div>
                        ) : this.state.page === moodPage ? (
                          <div onClick={this.handleNextMoodClick} className='next'>
                            Next
                          </div>
                        ) : this.state.page === feelPage ? (
                          <div onClick={this.handleNextFeelClick} className='next'>
                            Next
                          </div>
                        ) : this.state.page === helperPage ? (
                          <div onClick={this.handleNextHelperClick} className='next'>
                            Next
                          </div>
                        ) : this.state.page === maxPage ? (
                          <div onClick={this.handleBackClick} className='next'>
                            Next
                          </div>
                        ) : null
                      }
                    </div>
                  ) : null
                }
                
              </div>
            ) : null
          }
        </div>
        <Footer/>
      </div>
    )
  }
}

class RemoveIcon extends Component {
  state = {
    showConfirm: false
  }
  handleClick = () => {
    if (!this.state.showConfirm) {
      this.setState({showConfirm: true})
    }
    else {
      this.props.onClick(this.props.index)
    }
  }
  handleCancelClick = () => {
    this.setState({showConfirm: false})
  }
  render () {
    return (
      <div className='RemoveIcon'>
        {
          this.state.showConfirm ? (
            <div>
              <div className='cancel' onClick={this.handleCancelClick}>Cancel</div>
              <div className='remove' onClick={this.handleClick}>Remove</div>
            </div>
          ) : (
            <div className='minusBox' onClick={this.handleClick}>
              <svg className='minus' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
            </div>
          )
        }
      </div>
    )
  }
}

class FlagIcon extends Component {
  handleClick = () => { this.props.onClick(this.props.id) }
  render () {
    return (
      <img className='flag' onClick={this.handleClick} src={this.props.active ? flagActivePng : flagInactivePng} alt=''/>
    )
  }
}

class SaveButton extends Component {
  render () {
    return (
      <div className='saveButton' onClick={this.props.onClick}>
        Save
        {
          this.props.isSaving ? (
            <svg className='spinnerIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"/></svg>
          ) : (
            <svg className='saveIcon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
          )
        }
      </div>
    )
  }
}

class AddButton extends Component {
  render () {
    return (
      <div className='saveButton addButton' onClick={this.props.onClick}>
        Add
        {
          this.props.isSaving ? (
            <svg className='spinnerIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"/></svg>
          ) : (
            <svg className='saveIcon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
          )
        }
      </div>
    )
  }
}

class ScheduleOption extends Component {
  handleClick = () => this.props.onClick(this.props.index)
  render () {
    return (
      <div className={'scheduleOption '+(this.props.active ? 'active' : '')} onClick={this.handleClick}>
        {this.props.children}
      </div>
    )
  }
}

class DoseOption extends Component {
  handleClick = () => this.props.onClick(this.props.index, this.props.dose)
  render () {
    return (
      <div className={'scheduleOption '+(this.props.active ? 'active' : '')} onClick={this.handleClick}>
        {this.props.children}
      </div>
    )
  }
}

class Question extends Component {
  handleBackClick = () => this.props.onBackClick()
  handleNextClick = () => this.props.questions.length === this.props.questions.reduce((total, q) => total + this.props.answers.reduce((found, a) => (a.qid === q.id && a.oid !== null) ? found + 1 : found, 0), 0)
                            && this.props.onNextSetupClick()
  render () {
    return (
      <div className='Question'>
        <div className='longText'>
          { 
            this.props.index === 2 ? (
              <div className='questionTopText'>Page 2 of 5 - Medication </div>
            ) : this.props.index === 3 ? (
              <div className='questionTopText'>Page 3 of 5</div>
            ) : this.props.index === 4 ? (
              <div className='questionTopText'>Page 4 of 5<br />In a normal week, how many times do you do...</div>
            ) : this.props.index === 5 ? (
              <div className='questionTopText'>Page 5 of 5<br />In a normal week, how many times do you do...</div>
            ) : null
          }
          {
            this.props.index <= 5 ? (
              this.props.questions.map((question, x) => (
                <div key={x} className='questionBox'>
                  <div className='questionText'>
                    <div dangerouslySetInnerHTML={{ __html: question.text }} />
                  </div>
                  { 
                    this.props.index === 4 ? (
                      <div className="questionTimes">Times per week:</div>
                    ) : null
                  }
                  <div className='questionButtons'>
                    { 
                      question.id === 3 && this.props.doses !== null ? (
                          this.props.doses.map((o, i) => (
                            <Option key={question.id*10+i} q={question.id} index={i} oid={o.id} onClick={this.props.onOptionClick} active={this.props.answers.reduce((found, a) => (a.qid === question.id && a.oid === o.id) ? found || true : found, false)}>
                              {o.text}
                            </Option>
                          ))
                      ) : (
                          question.options.map((o, i) => (
                            <Option key={question.id*10+i} q={question.id} index={i} oid={o.id} onClick={this.props.onOptionClick} active={this.props.answers.reduce((found, a) => (a.qid === question.id && a.oid === o.id) ? found || true : found, false)}>
                              {o.text}
                            </Option>
                          ))
                      )
                    }
                  </div>
                </div>
              ))
            ) : null
          }
          <div className="buttons">
            { 
              this.props.index > minPage ? (
                <div onClick={this.handleBackClick} className='back'>
                  Back
                </div>
              ) : null
            }
            <div onClick={this.handleNextClick} className={'next '+(this.props.questions.length > this.props.questions.reduce((total, q) => total + this.props.answers.reduce((found, a) => (a.qid === q.id && a.oid !== null) ? found + 1 : found, 0), 0) ? 'inactive' : '')}>
              Next
            </div>
          </div>
        </div>
      </div>
    )
  }
}


class Option extends Component {
  handleClick = () => this.props.onClick(this.props.q, this.props.index, this.props.oid)
  render () {
    return (
      <div className='optionCont'>
        <div onClick={this.handleClick} className={'Option '+(this.props.active ? 'active' : '')}>
          {this.props.children}
        </div>
      </div>
    )
  }
}


class DateInput extends Component {
  handleChange = (e) => this.props.onChange(this.props.index, e.target.value)
  render () {
    return (
        <input type='text' className='textInputDate' value={this.props.date} onChange={this.handleChange} />
    )
  }
}
class DoseInput extends Component {
  handleChange = (e) => this.props.onChange(this.props.index, e.target.value)
  render () {
    return (
        <input type='text' className='textInputDose' value={this.props.dose} onChange={this.handleChange} />
    )
  }
}
class NoteInput extends Component {
  handleChange = (e) => this.props.onChange(this.props.index, e.target.value)
  render () {
    return (
        <input type='text' className='textInputNote' value={this.props.note} onChange={this.handleChange} />
    )
  }
}
