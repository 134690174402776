import React, { Component } from 'react'
import './DataConsent.css'

export default class DataConsent extends Component {
  render () {
    return (
      <div>
        <div className='DataConsent'>
          <div className='page'>
            <div className='portraitCont'>
                <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                <div className='caption'>Professor Jane Gunn</div>
            </div>
            <div className='quote'>
              Thank you for trying Wiser AD. To help our research, may we keep your information on file?
            </div>
          </div>
          <div className='wrapper'>
            <div className='msg'>
              <div className='title'>
                Why we collect your information
              </div>
              <div className="instruction">
                We would like to retain your information as this provides us with valuable data to help us understand  more about mental health in our population. Your data is de-identified which means that no-one will be able to identify you from the data we keep.
              </div>
            </div>
            <div className='msg'>
              <div className='title'>
                What you are agreeing to
              </div>
              <div className="instruction">
                We are asking that we can keep the deidentified data you have provided so far, so that we might use it in our analysis  later. You will not be contacted or identified in anyway.
              </div>
              <div className="buttons">
                <div onClick={this.props.handleBackClick} className='back'>
                  Back
                </div>
                <div onClick={this.props.handleSaveAgreeClick} className='next'>
                  I agree
                </div>
                <div onClick={this.props.handleDisagreeClick} className='next'>
                  I do not agree
                </div>
               </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

