import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer'
import './SignUp.css'

function getCookie(name) {
  var match = document.cookie.match(new RegExp('(?:^| )' + name + '=([^;]+)'));
  if (match) return match[1];
}

export default class SignUp extends Component {
  state = {
    page: 0,
    firstname: '',
    surname: '',
    email: '',
    password: '',
    error: null,
    firstnameError: '',
    surnameError: '',
    emailError: '',
    passwordError: '',
    isLoading: false,
  }

  handleSignUp = (e) => {
    e.preventDefault()
    this.setState({error: null, isLoading: true})
    fetch(window.apiEndpoint+'/api/register-user/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify(
        {
          first_name: this.state.firstname,
          last_name: this.state.surname,
          username: this.state.email,
          password1: this.state.password,
          password2: this.state.password,
          email: this.state.email,
        }
      ),
    })
    .then(res => res.json())
    .then((data) => {
      if (!data.errors) {
        this.setState({
          isLoading: false,
          firstnameError: ''
        })
        this.props.handleLogin(data)
      }
      else {
        this.setState({
          error: true,
          loading: false,
          firstnameError: data.errors.first_name ? data.errors.first_name[0] : '',
          surnameError: data.errors.last_name ? data.errors.last_name[0] : '',
          emailError: data.errors.email ? data.errors.email[0] : data.errors.username ? data.errors.username[0] : '',
          passwordError: data.errors.password1 ? data.errors.password1[0] : '',
        })
      }
    })
  }

  handleFirstnameChange = (e) => this.setState({firstname: e.target.value})
  handleSurnameChange = (e) => this.setState({surname: e.target.value})
  handleEmailChange = (e) => this.setState({email: e.target.value})
  handlePasswordChange = (e) => this.setState({password: e.target.value})

  handleBackClick = () => this.setState({page: 0})
  handleAgreeClick = () => this.setState({page: 1})
  handleLaterClick = () => this.setState({page: -1})

  render () {
    return (
      <div>
        <form className='SignUp' onSubmit={this.handleSignUp}>
          <div className='wrapper'>
            {
              this.state.page === 0 ? (
                <div>
                  <div className='topQuote'>
                    <div className='portraitCont'>
                      <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                      <div className='caption'>Professor Jane Gunn</div>
                    </div>
                    <div className='quote'>
                      Please agree to the following:
                    </div>
                  </div>
                  
                  <div className='longText'>
                    <div className='instruction'>If you want to proceed, you must make an appointment with your doctor to discuss going off antidepressant medication.</div>
                    <div className="agreeContainer">
                      <button type="button" className='agree' onClick={this.handleAgreeClick}>I Agree</button>
                      <div className='later' onClick={this.handleLaterClick}>Ask me in a month</div>
                    </div>
                  </div>
                </div>
              ) : this.state.page === 1 ? (
                <div className='form'>
                  <div className='title'>Please complete your details below.</div>
                  <div>
                    <label htmlFor="firstname" className={this.state.firstnameError ? 'errorText' : ''}>Name</label>
                    <input
                      className={'textInput inputMedium' + (this.state.firstnameError ? ' errorInput' : '')}
                      type="text"
                      name="firstname"
                      value={this.state.firstname}
                      onChange={this.handleFirstnameChange}
                    />
                    {this.state.firstnameError ? <label className='errorBottom'>{this.state.firstnameError}</label> : null}
                  </div>
                  <div className='tempHide'>
                    <label htmlFor="surname" className={this.state.surnameError ? 'errorText' : ''}>Last name</label>
                    <input
                      className={'textInput inputMedium' + (this.state.surnameError ? ' errorInput' : '')}
                      type="text"
                      name="surname"
                      value={this.state.surname}
                      onChange={this.handleSurnameChange}
                    />
                    {this.state.surnameError ? <label className='errorBottom'>{this.state.surnameError}</label> : null}
                  </div>
                  <div>
                    <label htmlFor="email" className={this.state.emailError ? 'errorText' : ''}>Email</label>
                    <input
                      className={'textInput inputMedium' + (this.state.emailError ? ' errorInput' : '')}
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleEmailChange}
                    />
                    {this.state.emailError ? <label className='errorBottom'>{this.state.emailError}</label> : null}
                  </div>
                  <div>
                    <label htmlFor="password" className={this.state.passwordError ? 'errorText' : ''}>Password</label>
                    <input
                      className={'textInput inputMedium' + (this.state.passwordError ? ' errorInput' : '')}
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handlePasswordChange}
                    />
                    {this.state.passwordError ? <label className='errorBottom'>{this.state.passwordError}</label> : null}
                  </div>

                  <input className='submit gap' type="submit" value='Sign up'/>
                  <div className='cancel' onClick={this.handleBackClick}>Back</div>
                  <div className='status'>
                    {
                      this.state.error ? (
                        <div className='errorText'>There was a problem signing up with these details. Please correct items marked in red above and try again.</div>
                      ) : this.state.isLoading ? (
                        'Signing up...'
                      ) : null
                    }
                  </div>
                </div>
              ) : this.state.page === -1 ? (
                <div>
                  <div className='topQuote'>
                    <div className='portraitCont'>
                      <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                      <div className='caption'>Professor Jane Gunn</div>
                    </div>
                    <div className='quote'>
                      Enter your email, and we‘ll send you a reminder in a month.
                    </div>
                  </div>
                  <div className='longText'>
                    <label>Email</label>
                    <input className='textInput inputSmall'/>
                    <Link className='submit' to="/">Send</Link>
                    <div className='cancel' onClick={this.handleBackClick}>Back</div>
                  </div>
                </div>
              ) : null
            }
          </div>
        </form>
        <Footer/>
      </div>
    )
  }
}
