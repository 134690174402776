import React, { Component } from 'react'
import Footer from '../../components/Footer'
import './About.css'

export default class About extends Component {
  render () {
    return (
      <div>
        <div className='About'>
          <div className='wrapper'>
            <div className='topQuote'>
              <div className='portraitCont'>
                <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                <div className='caption'>Professor Jane Gunn</div>
              </div>
              <div className='quote'>
                Privacy Statement
              </div>
            </div>
            <div className='longText'>
              <h4>This privacy statement relates only to the information automatically collected from you resulting from your visit to websites controlled by the University of Melbourne.</h4>
              <p>The University&rsquo;s <a href="https://policy.unimelb.edu.au/MPF1270">Information Security Policy</a> sets out our requirements for confidentiality, integrity and availability of information accessed through Information Technology facilities.</p>
              <p>The University is committed to protecting your privacy and processing personal information fairly and lawfully in compliance with applicable legislation. The collection of personal information by the University is governed by the <a href="http://www6.austlii.edu.au/cgi-bin/viewdb/au/legis/vic/num_act/padpa201460o2014317">Privacy and Data Protection Act 2014 (Vic)</a> and the <a href="http://www6.austlii.edu.au/cgi-bin/viewdb/au/legis/vic/consol_act/hra2001144">Health Records Act 2001 (Vic)</a> (together, <strong>Privacy Laws</strong>). The University is also considered to be a data controller for the purposes of the <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">EU General Data Protection Regulation 2016/679</a><strong> (GDPR) </strong>in relation to the collection of personal information from individuals located in the EU, while conducting certain activities.</p>
              <p>"Personal information" is defined broadly in the Privacy Laws and GDPR as information that relates to an identified or identifiable individual. For further information about how the University manages personal information, please view the <a href="http://policy.unimelb.edu.au/MPF1104">University&rsquo;s Privacy Policy</a> or visit our <a href="https://about.unimelb.edu.au/strategy/governance/compliance-obligations/privacy/privacy-impact-assessments">Privacy Webpage</a>.</p>
              <h2>Links</h2>
              <p>This privacy statement does not apply to external websites that are linked to the University&rsquo;s site. The University is not responsible for the privacy practices or content of external websites.</p>
              <p>When you link to another site, we recommend you read the privacy statement of that site to familiarise yourself with its privacy policy and practices.</p>
              <h2>Use of Cookies</h2>
              <p>The University of Melbourne website uses cookies, tracking pixels and similar technologies (collectively, &lsquo;cookies&rsquo;).</p>
              <p>A cookie is a small data file containing certain pieces of information that a website creates when you visit the site.&nbsp;&nbsp;By visiting websites under the control of the University, third-parties (such as Google or AdRoll) may place cookies on your browser or device for the purposes described below.</p>
              <p>These cookies may track how and when you use a site, which site you visited immediately before, data about targeted ads served to you, and they may store information about your computer, browser or device (including your IP address or your mobile device type and unique identifier).</p>
              <p>Cookies cannot be used to run programs or deliver viruses to your computer.</p>
              <h2>Reporting</h2>
              <p>The University uses and shares cookies to facilitate the sampled reporting of demographics and the interests of its site visitors via such services as Google Analytics, social media, and other third-party platforms. These platforms may allow us to collect information such as age, gender and interests to better understand the users that visit our site and identify how we can improve user experience and interaction.</p>
              <p>This feature is based on Display Advertising (for example, Google Analytics Demographics and Interest Reporting or Facebook Insights) and the data collected is used to provide better services and more relevant content to our users.</p>
              <h2>Remarketing</h2>
              <p>The University may sometimes use third-party cookies, including:</p><ul type="disc"><li>Remarketing with Google Analytics and similar tools to advertise online, including the Google AdWords tracking cookie. This means we will continue to show ads to you across the internet, specifically on the Google Display Network (GDN).</li><li>Prospecting, Re-Targeting and Dynamic Creative using cookies by third-party vendors to deliver targeted advertising to you when you visit third-party websites, including:<ul><li>Adroll</li><li>LinkedIn</li><li>Snapchat</li><li>Taboola</li><li>Facebook</li><li>Google</li></ul></li></ul>
              <p>We, and third-party vendors, use first-party cookies (such as the Google Analytics and AdRoll cookie) and third-party cookies (such as the DoubleClick cookie) to:</p><ul type="disc"><li>Inform, optimise, and serve ads to you on relevant websites on the GDN and elsewhere, based on your past visits to our website; and</li><li>Find out how ad impressions, ad services, and your interactions with these ad impressions and ad services are related to visits to our site.</li></ul>
              <p>The University respects your privacy and does not collect identifiable information by using third-party remarketing services provided by companies such as Google and AdRoll. Users may choose to opt-out of the use of such cookies.</p>
              <h2>Opt out</h2>
              <p>You can <a href="https://policies.google.com/technologies/partner-sites">view Google&rsquo;s Privacy &amp; Terms here.</a> You can opt-out of Google Analytics for Display Advertising and customise GDN ads using the<a href="https://myaccount.google.com/?nlr=1"> Ads Preferences Manager</a> or by downloading the <a href="https://tools.google.com/dlpage/gaoptout"> Google Analytics Opt-out Browser Add-on </a> .</p>
              <p>You can manage your preferences on other platforms by reviewing their privacy and security policies and settings, and can opt-out of other third-party vendors&rsquo; use of cookies by visiting:</p><ul type="disc"><li>the <a href="http://www.networkadvertising.org/choices">NAI website opt-out page</a> or</li><li>the <a href="http://www.aboutads.info">DAA opt-out page</a> or,</li><li>for EU users, the <a href="http://youronlinechoices.eu">EDAA opt-out page</a></li></ul>
              <h2>Use of site visit data</h2>
              <p>The University&rsquo;s website collects site visit data to better understand general user trends at an aggregate level and improve web performance, web services, and website maintenance.</p>
              <p>To the extent that site visit data could make you identifiable, the University will not attempt to identify individuals from the records the server automatically generates, unless required to do so as part of an internal investigation or for a law enforcement-related purpose, and then, only in compliance with the Privacy Laws and GDPR as applicable.</p>
              <p>The University may also use site visit information for security audits to protect against threats from hackers, and for other law enforcement and security purposes.</p>
              <h2>Disclosure of site visit data</h2>
              <p>The University will not disclose any personal information collected as part of your site visit to a third-party without your consent, unless we are required or authorised to do so by law. As part of an investigation into suspected unlawful or improper activity, a law enforcement agency or government agency may exercise its legal authority to inspect the web server's records (for example, in relation to hacking or abusive messages).</p>
              <h2>Security of your personal information</h2>
              <p>The University and its employees have a legislative obligation to protect your personal information by taking all reasonable steps to ensure the that the information we hold is protected from misuse, loss unauthorised access, modification or disclosure. Specifically, access to systems, applications and the collected data is restricted to authorised personnel only.</p>
              <p>In addition, any personal information collected and used to identify user trends (such as IP addresses) is aggregated and made anonymous during report generation.</p>
              <h2>Access and Correction</h2>
              <p>You may request access to, or correction of, your personal information held by the University, or exercise data subject rights under the GDPR if applicable, by contacting <a href="https://students.unimelb.edu.au/stop1" target="_blank" rel="noopener noreferrer">Stop1</a> or by phoning 13 MELB (13 6352).</p>
              <p>To access documents containing your personal information which are held by the University of Melbourne, please contact <a href="mailto:foi-officer@unimelb.edu.au">foi-officer@unimelb.edu.au</a>.</p>
              <h2>Updates to this privacy statement</h2>
              <p><strong> </strong></p>
              <p>We may occasionally make changes to this Privacy Statement, particularly where there are changes in relevant laws or where we adopt new working practices. We encourage you to regularly review this statement for any updates. The most recent substantive changes were made 15 March 2019.</p>
              <p><a href="https://about.unimelb.edu.au/strategy/governance/compliance-obligations/privacy/privacy-statements/student-privacy-statement">Read more about Privacy at the University of Melbourne</a></p>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
}
