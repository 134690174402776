import React, { Component } from 'react'
import './Thanks.css'

export default class Thanks extends Component {
  render () {
    return (
      <div>
        <div className='Thanks'>
          <div className='page'>
            <div className='portraitCont'>
                <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                <div className='caption'>Professor Jane Gunn</div>
            </div>
            <div className='quote'>
              If you haven’t already, please make a time to talk to your doctor about other options.
            </div>
          </div>
          <div className='wrapper'>
            <div className='msg'>
              <div className='title'>
                Thank you very much for your interest in the Wiser AD program.
              </div>
            </div>
            <div className='msg'>
              <div className='instruction'>
                We look forward to helping you in the future, at a time that is better for you.
              </div>
            </div>
            <div className="buttons"  onClick={this.props.onLogoutClick}>
              <div className="next active" onClick={this.props.onLogoutClick}>Close</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

