import React, { Component } from 'react'
import Footer from '../../components/Footer'
import '../../styles/clearfix.css'
import './Contact.css'

export default class Contact extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isEditingDoctor: false,
      isDoctorSaved: true,
      doctorName: props.doctor.name,
      doctorPhone: props.doctor.phone,
      doctorEmail: props.doctor.email,
      isEditingSupport: false,
      isSupportSaved: true,
      supportName: props.support.name,
      supportPhone: props.support.phone,
    }
  }

  handleEditDoctorClick = () => this.setState({isEditingDoctor: !this.state.isEditingDoctor})

  handleDoctorNameChange = (e) => this.setState({doctorName: e.target.value})
  handleDoctorPhoneChange = (e) => this.setState({doctorPhone: e.target.value})
  handleDoctorEmailChange = (e) => this.setState({doctorEmail: e.target.value})

  handleSaveDoctorClick = () => {
    this.setState({isSavingDoctor: true})
    this.props.onSaveDoctor({
      name: this.state.doctorName,
      phone: this.state.doctorPhone,
      email: this.state.doctorEmail,
    })
    .then(() => {
      this.setState({isDoctorSaved: true, isSavingDoctor: false, isEditingDoctor: false})
    })
    .catch((e) => {
      console.log(e)
    })
  }

  handleEditSupportClick = () => this.setState({isEditingSupport: !this.state.isEditingSupport})

  handleSupportNameChange = (e) => this.setState({supportName: e.target.value})
  handleSupportPhoneChange = (e) => this.setState({supportPhone: e.target.value})

  handleSaveSupportClick = () => {
    this.setState({isSavingSupport: true})
    this.props.onSaveSupport({
      name: this.state.supportName,
      phone: this.state.supportPhone,
    })
    .then(() => {
      this.setState({isSupportSaved: true, isSavingSupport: false, isEditingSupport: false})
    })
    .catch((e) => {
      console.log(e)
    })
  }



  render () {
    return (
      <div>
        <div className='Contact'>
          <div className='wrapper'>
            <div className='card'>
              <div className='name'>
                Doctor
                <div className='editIcon' onClick={this.handleEditDoctorClick}/>
              </div>
              {
                !this.state.isEditingDoctor ? (
                  <div>
                    <a href={'tel:'+this.props.doctor.phone} className='phone'>{this.props.doctor.name} — {this.props.doctor.phone}</a>
                    <a href={'mailto:'+this.props.doctor.email} className='email'>{this.props.doctor.email}</a>
                  </div>
                ) : (
                  <div>
                    <div className='spaced'>
                      <label htmlFor='doctorName'>Name</label>
                      <input name='doctorName' className='textInput' type='text' onChange={this.handleDoctorNameChange} value={this.state.doctorName}/>
                    </div>
                    <div className='spaced'>
                      <label htmlFor='doctorPhone'>Phone</label>
                      <input name='doctorPhone' className='textInput' type='phone' onChange={this.handleDoctorPhoneChange} value={this.state.doctorPhone}/>
                    </div>
                    <div className='spaced'>
                      <label htmlFor='doctorEmail'>Email</label>
                      <input name='doctorEmail' className='textInput' type='email' onChange={this.handleDoctorEmailChange} value={this.state.doctorEmail}/>
                    </div>
                    <div className='spaced saveBox'>
                      <div onClick={this.handleEditDoctorClick} className='back'>Cancel</div>
                      <SaveButton isSaving={this.state.isSavingDoctor} isSaved={this.state.isDoctorSaved} onClick={this.handleSaveDoctorClick}/>
                    </div>
                  </div>
                )
              }
            </div>
            <div className='card'>
              <div className='name'>
                Support person
                <div className='editIcon' onClick={this.handleEditSupportClick}/>
              </div>
              {
                !this.state.isEditingSupport ? (
                  <div>
                    <a href={'tel:'+this.props.support.phone} className='phone'>{this.props.support.name} — {this.props.support.phone}</a>
                    <a href={'mailto:'+this.props.support.email} className='email'>{this.props.support.email}</a>
                  </div>
                ) : (
                  <div>
                    <div className='spaced'>
                      <label htmlFor='supportName'>Name</label>
                      <input name='supportName' className='textInput' type='text' onChange={this.handleSupportNameChange} value={this.state.supportName}/>
                    </div>
                    <div className='spaced'>
                      <label htmlFor='supportPhone'>Phone</label>
                      <input name='supportPhone' className='textInput' type='phone' onChange={this.handleSupportPhoneChange} value={this.state.supportPhone}/>
                    </div>
                    <div className='spaced saveBox'>
                      <div onClick={this.handleEditSupportClick} className='back'>Cancel</div>
                      <SaveButton isSaving={this.state.isSavingSupport} isSaved={this.state.isSupportSaved} onClick={this.handleSaveSupportClick}/>
                    </div>
                  </div>
                )
              }
            </div>
            <div className='card'>
              <div className='name'>Emergency</div>
              <a href='tel:000' className='phone'>Police or ambulance — 000</a>
              <a href='tel:131 114' className='phone'>Lifeline 24 hour counselling service — 131 114</a>
            </div>
            <div className='card'>
              <div className='name'>Websites</div>
              <a href='https://www.beyondblue.org.au' className='website'>https://www.beyondblue.org.au</a>
            </div>
            <div className='card'>
              <div className='name'>Information</div>
              <a href='https://www.beyondblue.org.au/docs/default-source/resources/bl0125-antidepressant-medication-fact-sheet-acc.pdf' className='website'>Beyond Blue - Antidepressant Medication - Fact Sheet</a>
              <p>This fact sheet looks at what antidepressants do, how they work and where to get more information and help. (PDF Document 1.7Mb)</p>
            </div>
            <div className='card'>
              <div className='name'>WiserAD Team</div>
              <p>Available During Business Hours
              <br />Monday – Friday from 9:00am – 5:30pm
              <br />0481 458 054
              <br /><a href='mailto:wiserad-enquiries@unimelb.edu.au'>wiserad-enquiries@unimelb.edu.au</a></p>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
}

class SaveButton extends Component {
  render () {
    return (
      <div className='saveButton' onClick={this.props.onClick}>
        Save
        {
          this.props.isSaving ? (
            <svg className='spinnerIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"/></svg>
          ) : (
            <svg className='saveIcon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
          )
        }
      </div>
    )
  }
}
