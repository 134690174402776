import React, { Component } from 'react'
import './DataRetain.css'

export default class DataRetain extends Component {
  constructor (props) {
    super(props)
    this.state = { value: null, }
  }

  handleDeleteClick = () => { this.setState({ value: 'delete', }) }
  handleAnonymousClick = () => { this.setState({ value: 'anonymous', }) }
  handleHelpClick = () => { this.setState({ value: 'help', }) }

  handleNextClick = () => {
    if (this.state.value === 'delete') { this.setState({ screen: 'delete', }) }
    else if (this.state.value === 'anonymous') { this.setState({ screen: 'anonymous', }) }
    else if (this.state.value === 'help') { this.setState({ screen: 'help', }) }
  }

  handleDeleteBackClick = () => { this.setState({ screen: null, }) }

  render () {
    if (!this.state.screen) {
      return (
        <div>
          <div className='DataRetain'>
            <div className='page'>
              <div className='portraitCont'>
                <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                <div className='caption'>Professor Jane Gunn</div>
              </div>
              <div className='quote'>
                You have said you do not give consent for Wiser AD to collect information about you.
              </div>
            </div>
            <div>
              <div className='option_set'>
                Please choose from the options below
                <div onClick={this.handleDeleteClick} className={'option '+(this.state.value === 'delete' ? 'active' : '')}>
                  <div className='cb'/> Delete all of my information from Wiser AD.
                </div>
                <div onClick={this.handleAnonymousClick} className={'option '+(this.state.value === 'anonymous' ? 'active' : '')}>
                  <div className='cb'/> You may keep anonymous information for research purposes, but delete information that identifies me.
                </div>
                <div onClick={this.handleHelpClick} className={'option '+(this.state.value === 'help' ? 'active' : '')}>
                  <div className='cb'/> I need to talk to someone before I agree.
                </div>
              </div>
            </div>

            <div className="buttons">
              <div onClick={this.props.handleBackClick} className='back'>
                Back
              </div>
              <div onClick={this.handleNextClick} className={'next '+(this.state.value ? 'active' : '')}>
                Next
              </div>
            </div>
          </div>
        </div>
      )
    }
    else if (this.state.screen === 'delete') {
      return (
        <div>
          <div className='DataRetain'>
            <div className='page'>
              <div className='portraitCont'>
                <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                <div className='caption'>Professor Jane Gunn</div>
              </div>
              <div className='quote'>
                Thank you for considering the Wiser AD Program
              </div>
            </div>
            <div className='msg'>
              <div className='title'>
                Your information will be deleted when you select "Delete" below.
              </div>
            </div>

            <div className="buttons">
              <div onClick={this.handleDeleteBackClick} className='back'>
                Back
              </div>
              <div onClick={this.props.handleDeleteClick} className='next active'>
                Delete
              </div>
            </div>
          </div>
        </div>
      )
    }
    else if (this.state.screen === 'anonymous') {
      return (
        <div>
          <div className='DataRetain'>
            <div className='page'>
              <div className='portraitCont'>
                <div className='portrait'></div>
                <div className='caption'>Dr Susie Fletcher</div>
              </div>
              <div className='quote'>
                Thank you for considering the Wiser AD Program
              </div>
            </div>
            <div className='msg'> 
              <div className='title'>
                Information identifying you will be deleted when you select “Delete” below
              </div>
            </div>
            <div className='msg'> 
              <div className='title'>
                Anonymous data will be retained.
              </div>
            </div>
            <div className="buttons">
              <div onClick={this.handleDeleteBackClick} className='back'>
                Back
              </div>
              <div onClick={this.props.handleAnonymousClick} className='next active'>
                Delete
              </div>
            </div>
          </div>
        </div>
      )
    }
    else if (this.state.screen === 'help') {
      return (
        <div>
          <div className='DataRetain'>
            <div className='page'>
              <div className='portraitCont'>
                <div className='portrait'></div>
                <div className='caption'>Dr Susie Fletcher</div>
              </div>
              <div className='quote'>
                Thank you for considering the Wiser AD Program
              </div>
            </div>
            <div className='msg'>
              <div className='title'>
                Please discuss any concerns you have with your Nurse or Doctor. 
              </div>
              <div className='instruction'>
                Select "Resume" to keep going with Wiser AD.
              </div>
            </div>

            <div className="buttons">
              <div onClick={this.handleDeleteBackClick} className='back'>
                Back
              </div>
              <div onClick={this.props.handleBackClick} className='next active'>
                Resume
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

