import React, { Component } from 'react'
import '../../styles/clearfix.css'
import './EventPlot.css'

const dateString = (d) => {
  return d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2)
}

export default class EventPlot extends Component {
  constructor (props) {
    super(props)
    if (this.props.prescriptions.length >= 2) {
      this.startDate = this.props.prescriptions[0].date
      this.endDate = this.props.prescriptions[this.props.prescriptions.length - 1].date
    }
  }

  dateToOffset = (date) => (
    (new Date(date) - new Date(this.startDate)) / 25000000
  )

  renderStageLabel = ({date, dose}, i) => (
    <div className='stageLabel' style={{left: this.dateToOffset(date)+'%'}} key={i}>
      {dose}mg
    </div>
  )

  renderDot = ({date, redflag, good}, i) => {
    let tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const dayWidth = this.dateToOffset(dateString(tomorrow)) - this.dateToOffset(dateString(new Date()))
    return (
      <div className='dot' style={{
        left: this.dateToOffset(date)+'%',
        width: dayWidth+'%',
        background: redflag ? 'rgb(207, 42, 43)' : good ? 'rgb(244,201,0)' : 'rgb(109,111,113)',
      }} key={i}/>
    )
  }

  renderDots = ({dates}, i) => {
    let d = null
    const a = this.props.records.length ? new Date(this.props.records[this.props.records.length-1].date) : null
    if (a) {
      a.setDate(a.getDate() + 1)
      if (a > new Date()) {
        d = a
      }
      else {
        d = new Date()
      }
    }

    return (
      <div className='dotRow' key={i} style={{
        width: (this.dateToOffset(this.endDate) - this.dateToOffset(this.startDate))+'%',
      }}>
        <div className='progress' style={{
          left: this.dateToOffset(this.startDate)+'%',
          width: (this.dateToOffset(d) - this.dateToOffset(this.startDate))+'%',
        }}/>
        {dates.map(this.renderDot)}
      </div>
    )
  }

  renderLeftLabels = ({label}, i) => (
    <div key={i} className='leftLabel'>
      {label}
    </div>
  )

  renderDateLabel = (date, i) => (
    <div className='dateLabel' style={{left: this.dateToOffset(date)+'%'}} key={i}>
      {date.slice(8) + ' ' + new Date(date).toLocaleString('en-us', {month: 'short'})}
    </div>
  )

  renderTodayDot = () => {
    const today = new Date()
    let tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const dayWidth = this.dateToOffset(dateString(tomorrow)) - this.dateToOffset(dateString(today))
    return (
      <div className='todayDot' style={{
        left: this.dateToOffset(dateString(today))+'%',
        width: dayWidth+'%',
      }}/>
    )
  }

  renderDateLabels = () => {
    let labels = []
    let date = new Date(this.startDate)
    let endDate = new Date(this.endDate)
    let today = new Date()
    if (today > endDate) {
      endDate = today
    }
    while (date <= endDate) {
      labels.push(dateString(date))
      date.setDate(date.getDate() + 7)
    }
    return (
      <div className='dateLabels'>
        {labels.slice(0, labels.length - 1).map(this.renderDateLabel)}
      </div>
    )
  }

  initTodaysScroll = (c) => {
    if (c) {
      const today = this.dateToOffset(dateString(new Date()))
      c.scrollTo(today * c.offsetWidth / 100 - c.offsetWidth * 0.5, 0)
    }
  }

  render () {
    const labeledDates = [
      {
        label: 'Sleep',
        dates: this.props.records.filter(r => !r.sleep).map(r => ({
          date: r.date,
          redflag: r.symptoms.filter(s => s.category === 'sleep').reduce((isRedFlag, s) => isRedFlag || s.redflag, false),
        })).concat(this.props.records.filter(r => r.sleep).map(r => ({
          date: r.date,
          good: true,
        }))),
      },
      {
        label: 'Mood',
        dates: this.props.records.filter(r => !r.mood).map(r => ({
          date: r.date,
          redflag: r.symptoms.filter(s => s.category === 'mood').reduce((isRedFlag, s) => isRedFlag || s.redflag, false),
        })).concat(this.props.records.filter(r => r.mood).map(r => ({
          date: r.date,
          good: true,
        }))),
      },
      {
        label: 'Activity',
        dates: this.props.records.filter(r => !r.active).map(r => ({
          date: r.date,
          redflag: r.symptoms.filter(s => s.category === 'active').reduce((isRedFlag, s) => isRedFlag || s.redflag, false),
        })).concat(this.props.records.filter(r => r.active).map(r => ({
          date: r.date,
          good: true,
        }))),
      },
    ]

    return (
      <div className='EventPlot clearfix'>
        <div className='leftLabels'>
          {labeledDates.map(this.renderLeftLabels)}
          {this.renderLeftLabels({label: 'Meds'})}
        </div>
        <div className='percentageContainer' ref={this.initTodaysScroll}>
          <div className='percentageContainerSlider'>
            {this.renderTodayDot()}
            {this.renderDateLabels()}
            {labeledDates.map(this.renderDots)}
            <div className='stageLabels'>
              {this.props.prescriptions.slice(0, this.props.prescriptions.length).map(this.renderStageLabel)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
