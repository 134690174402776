import React, { Component } from 'react'
import flagActivePng from './flag_active.png'
import './Checkup.css'

export default class Checkup extends Component {
  state = {
    sleep: null,
    mood: null,
    activity: null,
    currentSection: 0,
    activeSymptoms: [],
    activeActivities: []
  }
  handleGoodSleepClick = () => this.setState({sleep: true, currentSection: 1})
  handleBadSleepClick = () => this.setState({sleep: false})
  handleGoodMoodClick = () => this.setState({mood: true, currentSection: 2})
  handleBadMoodClick = () => this.setState({mood: false})
  handleGoodActivityClick = () => this.setState({activity: true})
  handleBadActivityClick = () => this.setState({activity: false})
  handleRestartClick = () => this.setState({
    sleep: null,
    mood: null,
    activity: null,
    currentSection: 0,
    activeSymptoms: [],
    activeActivities: [],
  })
  handleNextClick = () => {
    this.setState({currentSection: this.state.currentSection + 1})
  }
  handleSubmitClick = () => {
    this.props.onRecordSubmit(this.state.activeSymptoms, this.state.sleep, this.state.mood, this.state.activity, this.state.activeActivities)
    .then(() => {
      this.setState({
        sleep: null,
        mood: null,
        activity: null,
        currentSection: 0,
        activeSymptoms: [],
        activeActivities: []
      })
    })
  }
  toggleSymptom = (id) => {
    this.setState({
      activeSymptoms: (
        this.state.activeSymptoms.indexOf(id) !== -1 ? (
          this.state.activeSymptoms.filter((k) => k !== id)
        ) : (
          [...this.state.activeSymptoms, id]
        )
      )
    })
  }
  toggleActivity = (id) => {
    this.setState({
      activeActivities: (
        this.state.activeActivities.indexOf(id) !== -1 ? (
          this.state.activeActivities.filter((k) => k !== id)
        ) : (
          [...this.state.activeActivities, id]
        )
      )
    })
  }
  render () {
    return (
      <div className='Checkup'>
        <div className={'doneCont '+(this.state.currentSection === 3 ? 'active' : '')}>
          <div>
            <div className='doneTitle'>
              All done for today <div className='done'></div>
            </div>
            <div className='doneTitleResponse'>
              You gave the following responses:
            </div>
            <div className='doneLeft'>
              <div className='doneSection'>
                {
                  this.state.activeSymptoms.map(id => {const a = this.props.symptoms.filter(s => s.id === id); return a.length ? a[0] : null}).filter(x=>x).filter(s => s.category === 'sleep').reduce((isRedFlag, s) => isRedFlag || s.redflag, false) ? (
                    <div className='square redSquare'/>
                  ) : !this.state.sleep ? (
                    <div className='square graySquare'/>
                  ) : (
                    <div className='square yellowSquare'/>
                  )
                }
                Did you sleep well last night:
                  <div className='doneAnswer'>
                  {
                    this.state.sleep ? (
                      'Yes'
                    ) : (
                      'No'
                    )
                  }
                  </div>
                  <div className='doneChoices'>
                  {
                    this.state.sleep ? (
                      null
                    ) : (
                      this.state.activeSymptoms.map(id => { const a = this.props.symptoms.filter(s => s.id === id && s.category === 'sleep'); return a.length ? ' (' + a[0].text + ')' : null }).filter(x => x).join(', ')
                    )
                  }
                  </div>
              </div>
              <div className={'doneSection'}>
                {
                  this.state.activeSymptoms.map(id => {const a = this.props.symptoms.filter(s => s.id === id); return a.length ? a[0] : null}).filter(x=>x).filter(s => s.category === 'mood').reduce((isRedFlag, s) => isRedFlag || s.redflag, false) ? (
                    <div className='square redSquare'/>
                  ) : !this.state.mood ? (
                    <div className='square graySquare'/>
                  ) : (
                    <div className='square yellowSquare'/>
                  )
                }
                Has your mood been good:
                  <div className='doneAnswer'>
                  {
                    this.state.mood ? (
                      'Yes'
                    ) : (
                      'No'
                    )
                  }
                  </div>
                  <div className='doneChoices'>
                  {
                    this.state.mood ? (
                      null
                    ) : (
                      this.state.activeSymptoms.map(id => { const a = this.props.symptoms.filter(s => s.id === id && s.category === 'mood'); return a.length ? ' (' + a[0].text + ')' : null }).filter(x => x).join(', ')
                    )
                  }
                  </div>
              </div>
              <div className='doneSection'>
                {
                  this.state.activeSymptoms.map(id => {const a = this.props.symptoms.filter(s => s.id === id); return a.length ? a[0] : null}).filter(x=>x).filter(s => s.category === 'active').reduce((isRedFlag, s) => isRedFlag || s.redflag, false) ? (
                    <div className='square redSquare'/>
                  ) : !this.state.activity ? (
                    <div className='square graySquare'/>
                  ) : (
                    <div className='square yellowSquare'/>
                  )
                }
                Have you been active:
                <div className='doneAnswer'>
                {
                  this.state.activity ? (
                    'Yes'
                  ) : (
                    'No'
                  )
                }
                </div>
                <div className='doneChoices'>
                  {
                    this.state.activity ? (
                      this.state.activeActivities.map(id => { const a = this.props.activities.filter(s => s.id === id); return a.length ? ' (' + a[0].text + ')' : null }).filter(x => x).join(', ')
                    ) : (
                      this.state.activeSymptoms.map(id => { const a = this.props.symptoms.filter(s => s.id === id && s.category === 'active'); return a.length ? ' (' + a[0].text + ')' : null }).filter(x => x).join(', ')
                    )
                  }
                </div>
              </div>
            </div>
            <div>
              <div className='instructionText2'>
                If you are happy with these please add them to your timeline and don’t forget to check in tomorrow!
              </div>
              <div className={'restartCont '+(this.state.activity === null && this.state.mood === null && this.state.sleep === null ? 'active' : '')} onClick={this.handleRestartClick}>
                <svg className='restart' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='#444'><path d="M0 0h24v24H0z" fill="none"/><path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"/></svg>
                Redo
              </div>
              <div className='submit' onClick={this.handleSubmitClick}>
                Add to time line
                <svg className='addIcon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
              </div>
            </div>
          </div>
        </div>

        <div className={'section '+(this.state.currentSection === 2 ? 'active' : '')}>
          <div className='question'>
            Have you been active?
          </div>
          <div className='answers'>
            <div className={'answer goodAnswer '+(this.state.activity ? 'active' : '')} onClick={this.handleGoodActivityClick}>
              <div className='answerImg goodActivity'/>
              Yes
            </div>
            <div className={'answer badCont '+(this.state.activity === false ? 'active' : '')+(this.state.activity === true ? 'inactive' : '')} onClick={this.handleBadActivityClick}>
              <div className='answerImg badActivity'/>
              No
            </div>
          </div>
          <div className={'subCont '+(this.state.activity ? 'active' : '')}>
            What did you do?
            <div>
            {
              this.props.activities.map(({id, text}) => (
                <Activity key={id} active={this.state.activeActivities.indexOf(id) !== -1} id={id} onClick={this.toggleActivity}>
                  {text}
                </Activity>
              ))
            }
            </div>
            <NextButton active={this.state.currentSection > 2} onClick={this.handleNextClick}/>
          </div>
          <div className={'subCont '+(this.state.activity === false ? 'active' : '')}>
            {
              this.props.symptoms.map(({id, text, category}, i) => (
                category === 'active' ? (
                  <SubAnswer key={id} active={this.state.activeSymptoms.indexOf(id) !== -1} onClick={this.toggleSymptom} symptomId={id}>
                    {this.props.symptoms[i].redflag ? <img className='flag' src={flagActivePng} alt=''/> : null}{text}
                  </SubAnswer>
                ) : null
              ))
            }
            <NextButton active={this.state.currentSection > 2} onClick={this.handleNextClick}/>
          </div>
        </div>
        <div className={'section '+(this.state.currentSection === 1 ? 'active' : '')}>
          <div className='question'>
            Has your mood been good?
          </div>
          <div className='answers'>
            <div className={'answer goodAnswer '+(this.state.mood ? 'active' : '')} onClick={this.handleGoodMoodClick}>
              <div className='answerImg goodMood'/>
              Yes
            </div>
            <div className={'answer badCont '+(this.state.mood === false ? 'active' : '')} onClick={this.handleBadMoodClick}>
              <div className='answerImg badMood'/>
              No
            </div>
          </div>
          <div className={'subCont '+(this.state.mood === false ? 'active' : '')}>
            {
              this.props.symptoms.map(({id, text, category}, i) => (
                category === 'mood' ? (
                  <SubAnswer key={text} active={this.state.activeSymptoms.indexOf(id) !== -1} onClick={this.toggleSymptom} symptomId={id}>
                    {this.props.symptoms[i].redflag ? <img className='flag' src={flagActivePng} alt=''/> : null}{text}
                  </SubAnswer>
                ) : null
              ))
            }
            <NextButton active={this.state.currentSection > 1} onClick={this.handleNextClick}/>
          </div>
        </div>
        <div className={'section '+(this.state.currentSection === 0 ? 'active' : '')}>
          <div className='instruction'>
            Daily well-being tracker
          </div>
          <div className='instructionText'>
            Once a day, please take a minute to answer the questions below.
          </div>
          <div className='question'>
            Did you sleep well last night?
          </div>
          <div className='answers'>
            <div className={'answer goodAnswer '+(this.state.sleep ? 'active' : '')} onClick={this.handleGoodSleepClick}>
              <div className='answerImg goodSleep'/>
              Yes
            </div>
            <div className={'answer badCont '+(this.state.sleep === false ? 'active' : '')} onClick={this.handleBadSleepClick}>
              <div className='answerImg badSleep'/>
              No
            </div>
          </div>
          <div className={'subCont '+(this.state.sleep === false ? 'active' : '')}>
            {
              this.props.symptoms.map(({id, text, category}, i) => (
                category === 'sleep' ? (
                  <SubAnswer key={text} active={this.state.activeSymptoms.indexOf(id) !== -1} onClick={this.toggleSymptom} symptomId={id}>
                    {this.props.symptoms[i].redflag ? <img className='flag' src={flagActivePng} alt=''/> : null}{text}
                  </SubAnswer>
                ) : null
              ))
            }
            <NextButton active={this.state.currentSection > 0} onClick={this.handleNextClick}/>
          </div>
        </div>
      </div>
    )
  }
}

class NextButton extends Component {
  render () {
    return (
      <div className={'next '+(this.props.active ? 'active' : '')} onClick={this.props.onClick}>
        Next
        <svg className='chevron' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
      </div>
    )
  }
}

class SubAnswer extends Component {
  handleClick = () => this.props.onClick(this.props.symptomId)
  render () {
    return (
      <div className={'subAnswer '+(this.props.active ? 'active' : '')} onClick={this.handleClick}>
        {this.props.children}
      </div>
    )
  }
}

class Activity extends Component {
  handleClick = () => this.props.onClick(this.props.id)
  render () {
    return (
      <div className={'activity '+(this.props.active ? 'active' : '')} onClick={this.handleClick}>
        {this.props.children}
      </div>
    )
  }
}
