import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer'
import './Survey.css'

export default class Survey extends Component {
  state = {
    page: 0,
    lifeEventPage: 0,
    lifeEventAnswer: this.props.firstAnswer,
    answers: this.props.answers,
    eligible: false,
    predict: 0,
    group: ''
  }
  handleBackClick = () => this.setState({page: this.state.page-1})
  handleNextClick = () => this.setState({page: this.state.page+1})
  handleEditQClick = (p) => this.setState({page: p})

  handleLifeEventNoClick = () => {
    this.props.submitSurveyOption(this.props.firstQuestion.id, this.props.firstQuestion.options[0].id, 0)
    this.setState({lifeEventAnswer: this.props.firstQuestion.options[0].id})
  }
  handleLifeEventYesClick = () =>  {
    this.props.submitSurveyOption(this.props.firstQuestion.id, this.props.firstQuestion.options[1].id, 0)
    this.setState({lifeEventAnswer: this.props.firstQuestion.options[1].id})
  }
  handleLifeEventNextClick = () => {
    if (this.state.lifeEventAnswer === this.props.firstQuestion.options[1].id) {
      this.setState({lifeEventPage: this.state.lifeEventPage + 1})
    }
    else if (this.state.lifeEventAnswer === this.props.firstQuestion.options[0].id) {
      this.setState({page: this.state.page + 1})
    }
  }

  handleNextQuestionClick = (q) => {
    this.setState({page: this.state.page+1})
    if (q === this.props.questions.length - 1) {
      this.props.getSurveyScore()
      .then(res => {
        if (!res.ok) {
          throw new Error()
        }
        else {
          return res
        }
      })
      .then(res => res.json())
      .then(({eligible, predict, group, homestep, user}) => {
        this.props.setHomestep(homestep, user)
        this.setState({ 
          eligible: eligible,
          predict: predict,
          group: group 
        })
      })
    }
  }
  handleOptionClick = (q, index, qid, oid) => {
    this.props.submitSurveyOption(qid, oid, q+1)
    this.setState({answers: this.state.answers.map((x, i) => i === q ? oid : x)})
  }

  handlePrintClick = () => window.print()

  render () {
    return (
      <div>
        <div className='Survey'>
          {
            this.state.page === 0 ? (
              <div className='page'>
                <div className='topQuote'>
                  <div className='portraitCont'>
                    <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                    <div className='caption'>Professor Jane Gunn</div>
                  </div>
                  <div className='quote'>
                    Welcome to Wiser about Anti Depressants (WiserAD)
                  </div>
                </div>
                <div className='longText'>
                  <p>WiserAD is about encouraging patients and their GPs to discuss medication for depression and think about reducing or ceasing (deprescribing) antidepressant medication when there is no longer a clinical need for it.</p>
                  <p>Antidepressant medication can be very helpful in helping people to cope with their depressive symptoms, however there are many people for whom antidepressants are no longer necessary as their depression is very mild or has gone away completely.</p>
                  <p>We know that many people are worried about stopping their medication in case of relapse. To help with this, researchers from the University of Melbourne have developed the WiserAD tool, which supports patients to re-evaluate their medication, with the help of their GP.</p>
                  <p>WiserAD will provide you the opportunity to reflect on your emotional health and well-being and your current need for antidepressant medication. With your help, we can find out if the WiserAD support tool is effective.</p>
                  <p>You will be helping to shape healthcare for people taking antidepressants both now and, in the future.</p>
                </div>
                { this.props.isLoggedIn ? (
                  <div onClick={this.handleNextClick} className='next'>
                    Tell me more
                  </div>
                  ) : null
                }
              </div>
            ) : this.state.page === 1 ? (
              <div className='page'>
                <div className='topQuote'>
                  <div className='portraitCont'>
                    <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                    <div className='caption'>Professor Jane Gunn</div>
                  </div>
                  <div className='quote'>
                    These are the next steps...
                  </div>
                </div>
                <div className='longText'>
                  <ol>
                    <li>Confirm your eligibility to participate</li>
                    <li>If you are eligible return in two weeks to confirm eligibility</li>
                    <li>Provide your consent to participate</li>
                    <li>Complete an online survey called REDCap</li>
                    <li>You will be automatically sent back to WiserAD to find out more about antidepressants and we may ask you to consider reducing your medication with our support plan</li>
                    <li>If you are asked to reduce your medication you will need to speak to your GP about your new prescription schedule that we recommend. We will also ask you to check in daily using our support tool.</li>
                  </ol>
                </div>
                <div onClick={this.handleBackClick} className='back'>
                  Back
                </div>
                <div onClick={this.handleNextClick} className='next'>
                  Do the survey
                </div>
              </div>
            ) : this.state.lifeEventPage === 2 ? (
              <div className='page'>
                <div className='title'>
                  Wiser AD Survey – Come back later
                </div>
                <div className='feedback'>
                  <div className='topQuote'>
                    <div className='portraitCont'>
                      <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                    </div>
                    <div className='quote'>
                      If you haven’t already, please make a time to talk to your doctor about other options.
                    </div>
                  </div>
                </div>
                <div className='longText noMargin'>
                  <p>Thank you very much for your interest in the Wiser AD program.</p>
                  <p>We look forward to helping you in the future, at a time that is better for you.</p>
                </div>
                <div>
                  <a href='/' className='closeSurvey'>Close</a>
                </div>
              </div>
            ) : this.state.page === 2 ? (
              <div className='page'>
                <div className='title'>
                  Is WiserAD for you?
                </div>
                {
                  this.state.lifeEventPage === 0 ? (
                    <div className='longText noMargin'>
                      <p>Let’s confirm your eligibility.</p>
                      <p>There are up to {this.props.questions.length+1} questions and can take approximately 10 minutes.</p>
                    </div>
                  ) : this.state.lifeEventPage === 1 ? (
                    <div className='feedback'>
                      <div className='topQuote'>
                        <div className='portraitCont'>
                          <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                        </div>
                        <div className='quote'>
                          This answer suggests that now might not be the best time for you to try the Wiser AD program.
                        </div>
                      </div>
                    </div>
                  ) : null
                }
                <div className='longText noMargin'>
                  <ol>
                    <li>
                      <p>{ this.props.firstQuestion.text }</p>
                    </li>
                  </ol>
                </div>
                {
                  this.state.lifeEventPage === 0 ? (
                    <div>
                      <div className="optionYesNo">
                        <div className='optionCont'>
                          <div className={'Option '+(this.state.lifeEventAnswer === this.props.firstQuestion.options[0].id ? 'active' : '')} onClick={this.handleLifeEventNoClick}>{ this.props.firstQuestion.options[0].text }</div>
                        </div>
                        <div className='optionCont'>
                          <div className={'Option '+(this.state.lifeEventAnswer === this.props.firstQuestion.options[1].id ? 'active' : '')} onClick={this.handleLifeEventYesClick}>{ this.props.firstQuestion.options[1].text }</div>
                        </div>
                      </div>
                      <div>
                        <div onClick={this.handleBackClick} className='back'>
                          Back
                        </div>
                        <div onClick={this.handleLifeEventNextClick} className={'next '+(this.state.lifeEventAnswer === null ? 'inactive' : '')}>
                          Next
                        </div>
                      </div>
                    </div>
                  ) : this.state.lifeEventPage === 1 ? (
                    <div>
                      <div className='longText shortCircuit'>
                        <div className='youSection'>
                          <div className='youAnswered'>You answered:</div>
                          <div className='youCont'>
                            <div className='optionFlag'>
                              <img className='flag' src={require('./flag.png')} alt=''/>
                              Yes
                            </div>
                          </div>
                        </div>
                        <div className='youSection'>
                          <div className='prefer'>
                            Would you prefer to:
                          </div>
                          <div onClick={this.handleNextClick} className='next youNext'>
                            Continue the survey
                          </div>
                          <div onClick={this.handleLifeEventNextClick} className='next youNext'>
                            Come back later
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                }
              </div>
            ) : this.state.page >= 3 && this.state.page-3 < this.props.questions.length ? (
              <div className='page'>
                <div className='title'>
                  Is WiserAD for you?
                </div>
                <Question question={this.props.questions[this.state.page-3]} index={this.state.page-3} onBackClick={this.handleBackClick} onNextClick={this.handleNextQuestionClick} answer={this.state.answers[this.state.page-3]} onOptionClick={this.handleOptionClick}/>
              </div>
            ) : this.state.page === this.props.questions.length + 3 ? (
              <div className='page'>
                <div className='title'>
                  Is WiserAD for you?
                </div>
                <div className='feedback'>
                  <div className='topQuote'>
                    <div className='portraitCont'>
                      <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                    </div>
                    <div className='quote'>
                      {
                        this.state.eligible ? (
                          "Congratulations! You are eligible to continue using WiserAD."
                        ) : (
                          "Thank you for your time. Now is not the best time to continue using WiserAD. You may like to talk to your GP about other options relating to your antidepressants."
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className='longText noMargin'>
                  <div>
                    {
                      this.state.eligible ? (
                        <div>
                          <p>You can now provide your consent to be a participant in this important research study. Click the button below to consent.</p>
                          <ul>
                            <li><div className='printIcon' onClick={this.handlePrintClick} /><div className='printText' onClick={this.handlePrintClick}>Print your answers</div>, as they are not saved in the system.</li>
                            {/*<li><Link to='/sign-up'>Sign up</Link> with Wiser AD.</li>*/}
                            <li><Link to='/'>Continue to Home - Consent to Participate</Link></li>
                            <li>Make an appointment with your doctor to discuss a plan to go off antidepressant medication.</li>
                          </ul>
                        </div>
                      ) : (
                        <div>
                          <p>You are welcome to come back in 2 weeks to try again or perhaps you would like to take part in our living labs <a href='https://medicine.unimelb.edu.au/research-groups/general-practice-research/mental-health-program/get-involved'>Living Labs program - Other Research Studies</a></p>
                          <ul>
                            <li><div className='printIcon' onClick={this.handlePrintClick} /><div className='printText' onClick={this.handlePrintClick}>Print your answers</div>, as they are not saved in the system.</li>
                          </ul>
                        </div>                      
                      )
                    }
                  </div>
                  
                  <div className='headingText'>
                    You answered
                  </div>
                  <ol>
                    <li>
                      <p>Have any major life events happened recently or are coming up in the next two months?</p>
                      <p>e.g. changing job, moving house, getting married, or something stressful or sad.</p>
                      <div className='answerText'>
                        { this.state.lifeEventAnswer === this.props.firstQuestion.options[0].id ? 'No' : 'Yes' }
                      </div>
                      <div onClick={() => this.handleEditQClick(2)} className='answerEdit'>
                        Edit
                      </div>
                    </li>
                  </ol>
                  {
                    this.props.questions.map((q, i) => (
                      <div key={i}>
                        <ol start={i+2}>
                          <li>
                            <div>
                              {q.text}
                            </div>
                            <div className='answerText'>
                              {
                                q.options.reduce((show, o) => (this.state.answers[i] === o.id) ? o.text : show, null)
                              }

                            </div>
                            <div onClick={() => this.handleEditQClick(i+3)} className='answerEdit'>
                              Edit
                            </div>
                          </li>
                        </ol>
                      </div>
                    ))
                  }
                </div>
                <div onClick={this.handleBackClick} className='back'>
                  Back
                </div>
                {/*<Link to='/sign-up' className='next'>*/}
                {
                  this.state.eligible ? (
                    <Link to='/' className='next'>
                      Continue to Home
                    </Link>
                 ) : null
                }
              </div>
            ) : null
          }
        </div>
        <Footer/>
      </div>
    )
  }
}

class Question extends Component {
  handleNextClick = () => this.props.answer !== null && this.props.onNextClick(this.props.index)
  render () {
    return (
      <div className='Question'>
        <div className='longText noMargin'>
          <ol start={this.props.index + 2}>
            <li>
              <div>
                {this.props.question.text}
              </div>
            </li>
          </ol>
          <div className="optionGroup">
            {
              this.props.question.options.map((o, i) => (
                <Option key={i} q={this.props.index} index={i} qid={this.props.question.id} oid={o.id} onClick={this.props.onOptionClick} active={(this.props.answer !== null && this.props.answer === o.id) ? true : false}>
                  {o.text}
                </Option>
              ))
            }
          </div>
          <div onClick={this.props.onBackClick} className='back'>
            Back
          </div>
          <div onClick={this.handleNextClick} className={'next '+(this.props.answer === null ? 'inactive' : '')}>
            Next
          </div>
        </div>
      </div>
    )
  }
}


class Option extends Component {
  handleClick = () => this.props.onClick(this.props.q, this.props.index, this.props.qid, this.props.oid)
  render () {
    return (
      <div className='optionCont'>
        <div onClick={this.handleClick} className={'Option '+(this.props.active ? 'active' : '')}>
          {this.props.children}
        </div>
      </div>
    )
  }
}
