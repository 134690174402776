import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './PublicNav.css'

export default class PublicNav extends Component {
  render () {
    return (
      <div className='PublicNav'>
        <div className='inner'>
          <div className='logo'>
            <NavLink exact to='/'><img className='logoImg' src={require('./logoWiserAd.png')} alt='Wiser AD'/></NavLink>
          </div>
          <div className='nav'>
            <NavLink exact className='navItem navLink' activeClassName='active' to='/'>
              Welcome
            </NavLink>
            {/*<NavLink className='navItem navLink' activeClassName='active' to='/about'>
              About
            </NavLink>
            <NavLink className='navItem navLink' activeClassName='active' to='/sign-up'>
              Sign up
            </NavLink>*/}
            <NavLink className='navItem navLink' activeClassName='active' to='/login'>
              Log in
            </NavLink>
          </div>
        </div>
      </div>
    )
  }
}
