import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect, withRouter } from 'react-router-dom'
import 'normalize.css'
import Nav from './components/Nav'
import PublicNav from './components/PublicNav'
import Home from './pages/Home'
import Plan from './pages/Plan'
import Contact from './pages/Contact'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import Survey from './pages/Survey'
import About from './pages/About'
import Consent from './pages/Consent'
import './App.css'

function getCookie(name) {
  var match = document.cookie.match(new RegExp('(?:^| )' + name + '=([^;]+)'));
  if (match) return match[1];
}


class _AppInner extends Component {
  state = {}

  componentDidMount() {
    fetch(window.apiEndpoint+'/api/login-check/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    })
    .then(res => {
      if (res.status === 204) { 
        this.getData()
        this.getSurvey()
      } else {
        throw new Error()
      }
    })
    .catch(() => {
      this.getSurvey()
      .then(() => {
        this.setState({
          isLoggedIn: false,
          canRender: true
        })
      })
    })
  }

  getData = () => {
    fetch(window.apiEndpoint+'/api/all-data/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    })
    .then(res => {
      if (!res.ok) {
        throw new Error()
      }
      else {
        return res
      }
    })
    .then(res => res.json())
    .then(({symptoms, records, prescriptions, prescriptionsDefault, user, schedule, schedules, doctor, support, activities, setupQuestions, setupAnswers, todayActivities, medication, adminActing}) => {
      this.setState({
        symptoms,
        records,
        prescriptions,
        prescriptionsDefault,
        user,
        schedule,
        schedules,
        doctor,
        support,
        activities,
        todayActivities,
        setupQuestions,
        setupAnswers,
        medication,
        isLoggedIn: true,
        canRender: true,
        startdose: user.startdose,
        timesperday: user.timesperday,
        homestep: user.homestep,
        adminActing,
      })
    })
    .catch(() => this.setState({canRender: true}))
  }

  handleLogin = ({symptoms, records, prescriptions, prescriptionsDefault, user, schedule, schedules, doctor, support, activities, setupQuestions, setupAnswers, todayActivities, medication, adminActing}) => {
    this.setState({
      symptoms,
      records,
      prescriptions,
      prescriptionsDefault,
      user,
      schedule,
      schedules,
      doctor,
      support,
      activities,
      todayActivities,
      setupQuestions,
      setupAnswers,
      medication,
      isLoggedIn: true,
      startdose: user.startdose,
      timesperday: user.timesperday,
      homestep: user.homestep,
      adminActing,
    })//, () => { this.state.schedule !== null ? this.props.history.push('/') : this.props.history.push('/plan') })
  }

  getSurvey = () => (
    fetch(window.apiEndpoint+'/api/survey-data/', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
    .then(res => {
      if (!res.ok) {
        throw new Error()
      }
      else {
        return res
      }
    })
    .then(res => res.json())
    .then(({questions, answers}) => {
      this.setState({
        surveyQuestions: questions,
        surveyAnswers: answers
      })
    })
  )

  submitSurveyOption = (questionId, optionId, index) => {
    fetch(window.apiEndpoint+'/api/survey-save-option/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        questionId,
        optionId,
      })
    })
    this.setState({surveyAnswers: this.state.surveyAnswers.map((x, i) => i === index ? optionId : x)})
  }

  getSurveyScore = () => (
    fetch(window.apiEndpoint+'/api/survey-score/', {
      credentials: 'include',
      method: 'GET',
    })
  )

  submitSetupOption = (questionId, optionId) => (
    fetch(window.apiEndpoint+'/api/setup-save-option/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        questionId,
        optionId,
      })
    })
  )

  getSetupScore = () => (
    fetch(window.apiEndpoint+'/api/setup-score/', {
      credentials: 'include',
      method: 'GET',
    })
  )

  addActivity = (text) => {
    fetch(window.apiEndpoint+'/api/add-activity/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        activity: {text},
      })
    })
    .then((r) => r.json())
    .then(({id}) => this.setState({activities: [...this.state.activities, {id, text}]}))
  }

  removeActivity = (id) => {
    this.setState({activities: this.state.activities.filter((a) => (a.id !== id))})

    fetch(window.apiEndpoint+'/api/delete-activity/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        id: id,
      })
    })
  }

  addSymptom = (symptom) => {
    fetch(window.apiEndpoint+'/api/add-symptom/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        symptom: {
          ...symptom,
        },
      })
    })
    .then((r) => r.json())
    .then(({id}) => this.setState({symptoms: [...this.state.symptoms, {...symptom, id}]}))
  }

  removeSymptom = (id) => {
    this.setState({symptoms: this.state.symptoms.filter((s) => (s.id !== id))})

    fetch(window.apiEndpoint+'/api/delete-symptom/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        id: id,
      })
    })
  }

  toggleFlag = (id) => {
    this.setState({symptoms: this.state.symptoms.map((symptom, i) => ({
      ...symptom,
      redflag: symptom.id === id ? !symptom.redflag : symptom.redflag,
    }))})

    const symptomArr = this.state.symptoms.filter(s => s.id === id)
    if (!symptomArr.length) {
      return
    }
    else {
      const symptom = symptomArr[0]

      fetch(window.apiEndpoint+'/api/save-symptom/', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify({
          symptom: {
            ...symptom,
            redflag: !symptom.redflag,
          },
        })
      })
    }
  }

  handleLogoutClick = () => {
    fetch(window.apiEndpoint+'/api/logout-user/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: '',
    })
    .then((res) => {
      if (res.status === 204) {
        this.setState({ isLoggedIn: false, canRender: false})
        this.props.history.push('/')
        this.getSurvey()
        .then(() => {
          this.setState({canRender: true})
        })
      }
      else if (res.status === 205) {
        window.location = '/dashboard/participant/'+this.state.user.id+'/'
      }
    })
  }

  submitRecord = (symptoms, sleep, mood, active, activities) => {
    const today = (new Date()).toISOString().slice(0,10)
    this.setState({
      records: [
        ...this.state.records.filter(({date}) => date !== today),
        {
          symptoms: symptoms.map((sid) => this.state.symptoms.find((s) => s.id === sid)),
          sleep,
          mood,
          active,
          activities,
          date: today,
        },
      ],
      todayActivities: activities,
    })

    return fetch(window.apiEndpoint+'/api/save-record/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        symptoms: symptoms,
        sleep: sleep,
        mood: mood,
        active: active,
        activities: activities,
      })
    })
  }

  saveUser = ({firstName, lastName, phone, email, password}) => {
    this.setState({user: {...this.state.user, firstName, lastName, phone, email}})
    return fetch(window.apiEndpoint+'/api/save-user/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        firstName,
        lastName,
        phone,
        email,
        password,
      })
    })
  }

  saveUserAgree = ({futureContact, consentAgree}) => {
    this.setState({user: {...this.state.user, futureContact, consentAgree}})
    return fetch(window.apiEndpoint+'/api/save-user-agree/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        futureContact,
        consentAgree,
      })
    })
  }

  saveSchedule = (scheduleId) => {
    this.setState({schedule: scheduleId})
    return fetch(window.apiEndpoint+'/api/save-schedule/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        schedule: scheduleId,
      })
    })
    .then(res => res.json())
    .then(({prescriptions, startdose, homestep}) => {
      this.setState({
        prescriptions: prescriptions,
        startdose: startdose,
        homestep: homestep
      })
    })
  }
  
  saveDose = (prescriptionId) => {
    return fetch(window.apiEndpoint+'/api/save-dose/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        prescription: prescriptionId,
      })
    })
    .then(res => res.json())
    .then(({prescriptions, startdose}) => {
      this.setState({
        prescriptions: prescriptions,
        startdose: startdose
      })
    })
  }

  saveMedCustom = (customDates) => {
    return fetch(window.apiEndpoint+'/api/save-medcustom/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        customDates: customDates,
      })
    })
    .then(res => res.json())
    .then(({prescriptions}) => {
      this.setState({
        prescriptions: prescriptions
      })
    })
  }

  saveDoctor = ({name, phone, email}) => {
    this.setState({doctor: {name, phone, email}})
    return fetch(window.apiEndpoint+'/api/save-doctor/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        name,
        phone,
        email,
      })
    })
  }


  saveSupport = ({name, phone, email}) => {
    this.setState({support: {name, phone}})
    return fetch(window.apiEndpoint+'/api/save-support/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        name,
        phone,
      })
    })
  }


  saveDataConsent = (value) => {
    return fetch(window.apiEndpoint+'/api/save-user-dataconsent/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({ value: value, })
    })
  }


  submitHomeStep = (value) => {
    this.setState({homestep: value})
    return fetch(window.apiEndpoint+'/api/save-homestep/', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({ homestep: value, })
    })
    .then(res => res.json())
    .then(({user}) => {
      this.setState({
        user
      })
    })
  }
  
  setHomestep = (homestep, user) => {
    this.setState({homestep: homestep})
    this.setState({user: user})
  }

  render() {
    if (!this.state.canRender) {
      return null
    }
    return (
      <div className='App'>
        <Switch>
          <Route exact path='/sign-up' render={() => (
            this.state.isLoggedIn ? (
              <Redirect to='/' />
            ) : (
              <div>
                <PublicNav/>
                <SignUp handleLogin={this.handleLogin}/>
              </div>
            )
          )}/>
          <Route path='/login' render={() => (
            this.state.isLoggedIn ? (
              <Redirect to='/' />
            ) : (
              <div>
                <PublicNav/>
                <Login handleLogin={this.handleLogin}/>
              </div>
            )
          )}/>
          <Route path='/about' render={() => (
            <div>
              {
                !this.state.isLoggedIn ? (
                  <PublicNav/>
                ) : (
                  <Nav/>
                )
              }
              <About/>
            </div>
          )}/>
          <Route exact path="/" render={() => (
            !this.state.isLoggedIn ? (
              <div>
                <PublicNav/>
                <Survey firstQuestion={this.state.surveyQuestions[0]} firstAnswer={this.state.surveyAnswers[0]} questions={this.state.surveyQuestions.slice(1)} answers={this.state.surveyAnswers.slice(1)} submitSurveyOption={this.submitSurveyOption} getSurveyScore={this.getSurveyScore} isLoggedIn={this.state.isLoggedIn} setHomestep={this.setHomestep} />
              </div>
            ) : (
              <div>
                <Home
                  activities={this.state.activities}
                  symptoms={this.state.symptoms}
                  prescriptions={this.state.prescriptions}
                  records={this.state.records}
                  onRecordSubmit={this.submitRecord}
                  schedules={this.state.schedules}
                  schedule={this.state.schedule}
                  timesperday={this.state.timesperday}
                  todayActivities={this.state.todayActivities}
                  homestep={this.state.homestep}
                  onHomeStep={this.submitHomeStep}
                  user={this.state.user}
                  history={this.props.history}
                  adminActing={this.state.adminActing}
                />
                <Nav
                  onLogoutClick={this.handleLogoutClick}
                  adminActing={this.state.adminActing}
                  user={this.state.user}
                />
              </div>
            )
          )}/>
          <Route exact path="/consent" render={() => (
            <div>
              <Consent
                isLoggedIn={this.state.isLoggedIn}
                user={this.state.user}
                onSaveDataConsent={this.saveDataConsent}
                onSaveUserAgree={this.saveUserAgree}
                onLogoutClick={this.handleLogoutClick}
              />
              <Nav
                onLogoutClick={this.handleLogoutClick}
                adminActing={this.state.adminActing}
                user={this.state.user}
              />
            </div>
          )}/>
          <Route exact path="/survey" render={() => (
            <div>
              <Survey firstQuestion={this.state.surveyQuestions[0]} firstAnswer={this.state.surveyAnswers[0]} questions={this.state.surveyQuestions.slice(1)} answers={this.state.surveyAnswers.slice(1)} submitSurveyOption={this.submitSurveyOption} getSurveyScore={this.getSurveyScore} isLoggedIn={this.state.isLoggedIn} setHomestep={this.setHomestep} />
              <Nav
                onLogoutClick={this.handleLogoutClick}
                adminActing={this.state.adminActing}
                user={this.state.user}
              />
            </div>
          )}/>
          <Route path="/plan" render={() => (
            !this.state.isLoggedIn ? (
              <Redirect to='/login' />
            ) : (
              <div>
                <Plan
                  activities={this.state.activities}
                  symptoms={this.state.symptoms}
                  prescriptions={this.state.prescriptions}
                  prescriptionsDefault={this.state.prescriptionsDefault}
                  user={this.state.user}
                  adminActing={this.state.adminActing}
                  startdose={this.state.startdose}
                  timesperday={this.state.timesperday}
                  onSaveUser={this.saveUser}
                  schedule={this.state.schedule}
                  schedules={this.state.schedules}
                  onAddActivity={this.addActivity}
                  onAddSymptom={this.addSymptom}
                  onRemoveActivity={this.removeActivity}
                  onRemoveSymptom={this.removeSymptom}
                  onFlagToggle={this.toggleFlag}
                  onSaveSchedule={this.saveSchedule}
                  onSaveDose={this.saveDose}
                  onSaveMedCustom={this.saveMedCustom}

                  doctor={this.state.doctor}
                  onSaveDoctor={this.saveDoctor}
                  support={this.state.support}
                  onSaveSupport={this.saveSupport}

                  setupQuestions={this.state.setupQuestions}
                  setupAnswers={this.state.setupAnswers}
                  medication={this.state.medication}
                  submitSetupOption={this.submitSetupOption}
                  getSetupScore={this.getSetupScore}

                  onLogoutClick={this.handleLogoutClick}
                />
                <Nav
                  onLogoutClick={this.handleLogoutClick}
                  adminActing={this.state.adminActing}
                  user={this.state.user}
                />
              </div>
            )
          )}/>
          <Route path="/contact" render={() => (
            !this.state.isLoggedIn ? (
              <Redirect to='/login' />
            ) : (
              <div>
                <Contact
                  doctor={this.state.doctor}
                  onSaveDoctor={this.saveDoctor}
                  support={this.state.support}
                  onSaveSupport={this.saveSupport}
                />
                <Nav
                  onLogoutClick={this.handleLogoutClick}
                  adminActing={this.state.adminActing}
                  user={this.state.user}
                />
              </div>
            )
          )}/>
          <Route render={() => (
            <Redirect to='/' />
          )}/>
        </Switch>
      </div>
    )
  }
}

const AppInner = withRouter(_AppInner)

export default class App extends Component {
  render () {
    return (
      <BrowserRouter>
        <AppInner/>
      </BrowserRouter>
    )
  }
}
