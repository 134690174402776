import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Footer from '../../components/Footer'
import DataConsent from '../../pages/DataConsent'
import DataRetain from '../../pages/DataRetain'
import Thanks from '../../pages/Thanks'
import './Consent.css'
import PDF from './WiserAD-Participation-Consent-v1.pdf'

export default class Consent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isUserSaved: false,
      futureContact: false,
      consentAgree: false,
      showDataConsentForm: false,
    }
  }
  
  handleSaveAgreeClick = () => {
    this.setState({
      showDataConsentForm: false,
      consentAgree: true,
    })
    this.props.onSaveUserAgree({
      futureContact: this.state.futureContact,
      consentAgree: true,
    })
    .catch((e) => {
      console.log(e)
    })
  }

  showBackConsentForm = () => {
    this.setState({
      showDataConsentForm: true,
    })
  }

  handleDataConsentBackClick = () => {
    this.setState({
      showDataConsentForm: false,
    })
  }

  handleDataConsentAgreeClick = () => {
    this.props.onSaveDataConsent('agree')
    this.setState({
      showDataConsentForm: false,
      showThanks: true,
    })
  }

  handleDataConsentDisagreeClick = () => {
    this.setState({
      showDataConsentForm: false,
      showDataRetainForm: true,
    })
  }

  handleDataRetainBackClick = () => {
    this.setState({
      showDataConsentForm: true,
      showDataRetainForm: false,
    })
  }

  handleRetainDeleteClick = () => {
    this.props.onSaveDataConsent('delete')
    this.setState({
      showThanks: true,
      showDataRetainForm: false,
    })
  }

  handleRetainAnonymousClick = () => {
    this.props.onSaveDataConsent('anonymous')
    this.setState({
      showThanks: true,
      showDataRetainForm: false,
    })
  }
  
  handleFutureContactChange = (e) => this.setState({
    futureContact: e.target.checked ? true : false,
    isUserSaved: false,
  })

  render () {
    if (this.state.showDataConsentForm) {
      return (
        <DataConsent
          handleBackClick={this.handleDataConsentBackClick}
          handleSaveAgreeClick={this.handleDataConsentAgreeClick}
          handleDisagreeClick={this.handleDataConsentDisagreeClick}
        />
      )
    }
    else if (this.state.showDataRetainForm) {
      return (
        <DataRetain
          handleBackClick={this.handleDataRetainBackClick}
          handleDeleteClick={this.handleRetainDeleteClick}
          handleAnonymousClick={this.handleRetainAnonymousClick}
        />
      )
    }
    else if (this.state.showThanks) {
      return (
        <Thanks
          onLogoutClick={this.props.onLogoutClick}
        />
      )
    }
    else if (this.state.consentAgree) {
      return (
        <Redirect to="/" />
      )
    }
    return (
      <div>
        <div className='Consent'>
          <div className='topGap'></div>
              <div className='firstTime'>
                <div className='topQuote'>
                  <div className='portraitCont'>
                    <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                    <div className='caption'>Professor Jane Gunn</div>
                  </div>
                  <div className='quote'>
                      For you to participate in the Wiser AD program, we need your consent to gather information about you, and to monitor your progress during the program.
                  </div>
                </div>
                <div className='wrapper'>
                  <div className='group'>
                    <div className='title'>
                      Participant - Consent Form
                      <div className='pdfdownload'>(<a href={PDF} target='_blank' rel='noopener noreferrer'>Download as PDF</a>)</div>
                    </div>
                    <div className="instruction">
                      <p>Department of General Practice<br />Melbourne Medical School<br />Faculty of Medicine, Dentistry and Health Sciences</p>
                      <p>Project: WiserAD<br />Principal Investigator:  Professor Jane Gunn<br />Trial Manager:  Dr Cath Kaylor-Hughes<br />Phone:  0481 458 054<br />Email: <a href='mailto:wiserad-enquiries@unimelb.edu.au'>wiserad-enquiries@unimelb.edu.au</a></p>
                      <ol>
                        <li>I consent to participate in this project, the details of which have been explained to me, and I have been provided with a written plain language statement to keep.</li>
                        <li>I understand that the purpose of this research is to investigate the efficacy of the WiserAD online support tool in helping people to reassess their antidepressants.</li>
                        <li>I understand that my participation in this project is for research purposes only.</li>
                        <li>I acknowledge that the possible effects of participating in this research project have been explained to my satisfaction.</li>
                        <li>In this project I will be required to interact with an online support tool and complete online surveys at 3, 6-, 12-, 18- and 24-month intervals.</li>
                        <li>I understand that the research team will contact my GP regarding my mental health.</li>
                        <li>I understand that my participation is voluntary and that I am free to withdraw from this project anytime without explanation or prejudice and to withdraw any unprocessed data that I have provided.</li>
                        <li>I understand that the data from this research will be stored at the University of Melbourne and will be destroyed 15 years after the research has been concluded.</li>
                        <li>I have been informed that the confidentiality of the information I provide will be safeguarded subject to any legal requirements; my data will be password protected and accessible only by the named researchers.</li>
                        <li>I understand that after I sign and return this consent form, it will be retained by the researcher.</li>
                        <li>I consent to the research team contacting me for a follow ‐up interview about my experience of being involved in the study. The interview will be recorded. It is optional to participate.</li>
                      </ol>
                      {/* <p><strong>Optional Consent</strong></p>
                      <p><input name="futureContact" type="checkbox" checked={this.state.futureContact} onChange={this.handleFutureContactChange} /> I agree to future contact about related research in this area.</p> */} 
                    </div>
                  </div>
                  <div className='group'>
                    <div onClick={this.showBackConsentForm} className='back'>
                      I do not agree
                    </div>
                    <div onClick={this.handleSaveAgreeClick} className='next'>
                      I agree
                    </div>
                  </div>
                </div>
              </div>
        </div>
        <Footer/>
      </div>
    )
  }
}


