import React, { Component } from 'react'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import './Login.css'

function getCookie(name) {
  var match = document.cookie.match(new RegExp('(?:^| )' + name + '=([^;]+)'));
  if (match) return match[1];
}

export default class Login extends Component {
  state = {
    username: '',
    password: '',
    error: null,
    usernameError: '',
    passwordError: '',
    isLoading: false,
  }

  handleLogin = (e) => {
    e.preventDefault()
    this.setState({error: null, isLoading: true})
    fetch(window.apiEndpoint+'/api/login-user/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({username: this.state.username, password: this.state.password}),
    })
    .then(res => res.json())
    .then((data) => {
      if (!data.errors) {
        this.setState({isLoading: false})
        this.props.handleLogin(data)
      }
      else {
        this.setState({
          isLoading: false,
          error: data.errors.__all__ ? data.errors.__all__[0] : 'Please check your login details above.',
          usernameError: data.errors.username ? data.errors.username[0] : '',
          passwordError: data.errors.password ? data.errors.password[0] : '',
        })
      }
    })
  }

  handleUsernameChange = (e) => this.setState({username: e.target.value})
  handlePasswordChange = (e) => this.setState({password: e.target.value})

  render () {
    return (
      <div>
        <form className='Login' onSubmit={this.handleLogin}>
          <div className='wrapper'>
            <div className='topMargin'>
              <label htmlFor="username" className={this.state.usernameError ? 'errorText' : ''}>Email</label>
            </div>
            <div>
              <input
                className={'textInput' + (this.state.usernameError ? ' errorInput' : '')}
                type="text"
                name="username"
                autoCapitalize='none'
                value={this.state.username}
                onChange={this.handleUsernameChange}
              />
            </div>
            {this.state.usernameError ? <label className='errorBottom'>{this.state.usernameError}</label> : null}
            <div>
              <label htmlFor="password" className={this.state.passwordError ? 'errorText password' : 'password'}>Password</label>
              <div className="forgot">
                <a href="/accounts/password_reset/">
                  Forgot my password
                </a>
              </div>
            </div>
            <div>
              <input
                className={'textInput' + (this.state.passwordError ? ' errorInput' : '')}
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
              />
            </div>
            {this.state.passwordError ? <label className='errorBottom'>{this.state.passwordError}</label> : null}
            <Link className='cancelLink' to="/">Cancel</Link>
            <input className='submit' type="submit" value='Log in'/>
            <div className='status'>
              {
                this.state.error ? (
                  <div className='errorText'>{this.state.error}</div>
                ) : this.state.isLoading ? (
                  'Logging in...'
                ) : null
              }
            </div>
          </div>
        </form>
        <Footer/>
      </div>
    )
  }
}
